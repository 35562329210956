@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(0.2);
    opacity: 0.2;
  }
  20% {
    transform: scale(0.4);
    opacity: 0.4;
  }
  30% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  40% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  60% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  80% {
    transform: scale(1.6);
    opacity: 0.4;
  }
  90% {
    transform: scale(1.6);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

%animation-share {
  content: '';
  left: 50%;
  top: 50%;
  position: absolute;
  width: 1em;
  height: 1em;
  margin: -50% -50%;
  border-radius: 50%;
  border: 1px solid #ec2020;
}

.col {
  margin-bottom: 30px;
}

.ruleCol_width {
  width: 400px;
}

.alarmTable {
  :global {
    .ant-table-sticky-scroll {
      display: none;
    }
  }
}

.wrapper {
  min-height: calc(100vh - 130px) !important;
}

.col {
  margin-bottom: 30px;
}

.expend {
  position: absolute;
  right: 0px;
}

.colspan {
  display: flex;
}

.div_positon {
  position: relative;
}
.display_none {
  display: none;
}

.top_filter {
  box-sizing: border-box;
  background: var(--mx-module-bg-color);
  margin-bottom: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  .filterTitle {
    margin-bottom: 0;
  }

  .filter_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .icon {
      position: relative;
      color: #ec2020;
      &::before {
        @extend %animation-share;
        animation: wave 2s ease-in-out 0.4s infinite normal none;
      }

      &::after {
        @extend %animation-share;
        animation: wave 2s ease-in-out 1.6s infinite normal none;
      }
    }

    & > div:last-child {
      margin-right: 0;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      list-style: none;
      width: 160px;
      height: 46px;
      background: rgba(var(--mx-main-color), 0.08);
      border-radius: 2px;
      margin-right: 16px;

      & > span {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }

      div {
        display: flex;
        align-items: center;
      }

      div > span:nth-of-type(1) {
        display: inline-block;
        width: 2px;
        height: 12px;
        border-radius: 1px;
        margin-right: 3px;
      }
      div > span:nth-of-type(2) {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: var(--mx-text-base-color);
      }
    }
    .total {
      span:nth-of-type(1) {
        color: #dc2b2d;
      }
    }
    .highest_color {
      div > span:nth-of-type(1) {
        background-color: #e9494b;
      }

      & > span {
        color: #e9494b;
      }
    }

    .high_color {
      div > span:nth-of-type(1) {
        background-color: #d89614;
      }

      & > span {
        color: #d89614;
      }
    }

    .mid_color {
      div > span:nth-of-type(1) {
        background-color: #177ddc;
      }

      & > span {
        color: #177ddc;
      }
    }

    .low_color {
      div > span:nth-of-type(1) {
        background-color: #49aa19;
      }

      & > span {
        color: #49aa19;
      }
    }
    .lowest_color {
      div > span:nth-of-type(1) {
        background-color: #abd335;
      }

      & > span {
        color: #abd335;
      }
    }
  }
}

.form {
  :global {
    .ant-picker {
      width: 100%;
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: flex-end;
  align-items: center;

  .total {
    // margin-right: 20px;
    color: var(--mx-text-base-color);
  }
}

.alarm_card {
  padding: 15px 0 0 10px;
  margin-bottom: 0;
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.outer {
  cursor: pointer;
  user-select: none;
}
.select_item {
  width: 120px;
  height: 40px;
  transition: all 0.3s;
  background: rgba(233, 73, 75, 0.1);
  border-radius: 2px;
  border: 1px solid rgba(233, 73, 75, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  color: #e9494b;
  position: relative;
  .label {
    font-size: 12px;
  }
  .value {
    font-weight: 600;
  }
  .select_icon {
    color: var(--mx-text-base-color);
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 10px;
    background: #e9494b;
    border-radius: 0px 2px 0px 4px;
    position: absolute;
    top: -1px;
    right: -1px;
    opacity: 1;
    span {
      transform: scale(0.8);
    }
  }
}
.most {
  background: rgba(233, 73, 75, 0.1);
  border: 1px solid rgba(233, 73, 75, 0.4);
  color: #e9494b;
  .select_icon {
    background: #e9494b;
  }
}
.high {
  background: rgba(216, 150, 20, 0.1);
  border: 1px solid rgba(216, 150, 20, 0.4);
  color: #d89614;
  .select_icon {
    background: #d89614;
  }
}
.middle {
  background: rgba(23, 125, 220, 0.1);
  border: 1px solid rgba(23, 125, 220, 0.4);
  color: #177ddc;
  .select_icon {
    background: #177ddc;
  }
}
.low {
  background: rgba(73, 170, 25, 0.1);
  border: 1px solid rgba(73, 170, 25, 0.4);
  color: #49aa19;
  .select_icon {
    background: #49aa19;
  }
}
.lost {
  background: rgba(171, 211, 53, 0.1);
  border: 1px solid rgba(171, 211, 53, 0.4);
  color: #abd335;

  .select_icon {
    background: #abd335;
  }
}

.none {
  background: rgba(var(--mx-main-color), 0.05);
  border: 1px solid rgba(var(--mx-main-color), 0.1);
  color: rgba(var(--mx-main-color), 0.3);
  .select_icon {
    // display: none;
    opacity: 0;
  }
}

.color_0 {
  &::before {
    content: '● ';
    color: #49aa19;
  }
}
.color_1 {
  &::before {
    content: '● ';
    color: #a61d24;
  }
}
.color_3 {
  &::before {
    content: '● ';
    color: #d89614;
  }
}

.content {
  min-height: calc(100vh - 310px);
  padding: 20px;
}
