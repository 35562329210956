.wrapper {
  min-height: calc(100vh - 128px);
  padding: 20px;
}

.DashBoardStyle {
  height: 100px;
  border-top: 1px solid #ffffff10;
  border-left: 1px solid #ffffff10;
  border-bottom: 1px solid #ffffff10;
  background-color: #39393a;
  .DashBoardColStyle {
    border-right: 1px solid #ffffff10;
    padding: 17px 16px;
  }
}

.pageFilter {
  background: var(--component-background);
  padding-top: 20px;
}

.typeChangeStyleRadioGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
