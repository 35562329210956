.ColSty {
  background: #232324;
  padding: 10px;
  min-height: 210px;
  // margin-right: 10px;
}

.TableSty {
  max-height: 150px;
  overflow-y: auto;
  :global {
    .ant-empty-normal {
      margin: 0;
    }
  }
}
.stationNameBtn {
  padding: 0;
  font-size: 12px;
  height: 0;
  display: flex;
  align-items: center;
}

.stationNameSty {
  display: inline-block;
  width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
