.basic_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .basic_box_title {
    width: 640px;
    padding: 15px 0 10px;
  }
  .form_item_box {
    width: 640px;
    height: 64px;

    background: #2d2d2d;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 80px;
    box-sizing: border-box;
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    .check_box {
      padding-left: 24px;
      position: relative;
      .line_box {
        position: absolute;
        left: 32px;
        top: 24px;
        height: 64px;
        width: 16px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    &:nth-child(3) {
      .check_box {
        padding-left: 48px;
        .line_box {
          left: 56px;
        }
      }
    }
    &:nth-child(4) {
      .check_box {
        padding-left: 72px;
        .line_box {
          left: 80px;
        }
      }
    }
    &:nth-child(5) {
      .check_box {
        padding-left: 96px;
        .line_box {
          left: 104px;
        }
      }
    }
    &:nth-child(6) {
      .check_box {
        padding-left: 120px;
      }
    }
    &:nth-child(7) {
      .check_box {
        padding-left: 144px;
      }
    }
  }
}
.pcs_box {
  .sort {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #00adff;
  }

  .sortTd {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .add_pcs {
    margin-bottom: 10px;
  }
  .title {
    padding: 8px 0;
    font-size: 16px;
  }
  .tips_text {
    color: #faad14;
  }
}

.stack_box {
  .add_btn {
    margin-bottom: 10px;
  }
  .title {
    padding: 8px 0;
  }
  .tips_text {
    color: #faad14;
    display: flex;
    align-items: center;
  }
}
.rack_box, .pack_box, .stack_box, .cell_box {
  .form_box {
    .title {
      font-size: 16px;
      padding-bottom: 20px;
    }
  }
  .table_box {
    padding-top: 10px;
    .primary_color {
      color: var(--primary-color);
      cursor: pointer;
      padding-bottom: 10px;
    }
    .table_title {
      font-size: 16px;
      padding-bottom: 10px;
    }
  }
}
.stack_box {
  
  .table_box {
    padding-bottom: 10px;
    .primary_color {
      padding-bottom: 0;
    }
    .table_title {
      padding-bottom: 0px;
    }
  }
}
.pack_box {
  .content {
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 15px;
    }


    .footer {
      background-color: var(--component-background);
      z-index: 999;
      padding-bottom: 10px;
    }
  }
}

.cell_box {
  .content {
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 15px;
    }

    .col {
      display: flex;
      align-items: center;
    }

    .footer {
      background-color: var(--component-background);
      z-index: 999;
      padding-bottom: 10px;
    }
  }
}

.pcs_tips {
  padding-top: 8px;
  color: rgba(255,255,255,0.65);
  .tips_txt {
    padding-right: 5px;
  }
  .full_count {
    color: #52C41A;
  }
  .unfull_count {
    color: #FAAD14
  }
}
