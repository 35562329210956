.DetailInfo {
  // height: calc(100vh - 226px);
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .DetailInfoBtnSty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 12px;
  }
  .DetailInfoTwoSty {
    // height: 140px;
    width: 100%;
    background: linear-gradient(135deg, #303b51 0%, #1a2737 100%);
    padding: 12px;
    .DetailInfoTwoStyTitle {
      padding: 6px 0px;
    }
    .DetailInfoTwoItemSty {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 6px;
      .ItemInfoStyle {
        color: #00adff;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
}

.DetailInComing {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ffffff12;
  border-bottom: 1px solid #ffffff12;
  .TopItemInfo {
    display: flex;
    align-items: center;
    width: 147px;
    padding: 6px 3px;
    .TopLabel {
      font-size: 12px;
      line-height: 17px;
      padding-bottom: 4px;
    }
    .TopValue {
      font-size: 16px;
      color: #00adff;
    }
  }
}
.inComingPercent {
  width: 147px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .percentValue {
    color: #59d744;
  }
  .TopLabel {
    font-size: 12px;
    line-height: 17px;
    padding-bottom: 4px;
  }
}
