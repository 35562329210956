%animation-share {
  content: '';
  left: 50%;
  top: 50%;
  position: absolute;
  width: 1em;
  height: 1em;
  margin: -50% -50%;
  border-radius: 50%;
  border: 1px solid #ec2020;
}

.wrapper {
  // width: 100%;
  // height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1px;
  // background-image: url(../../imgs/bg.png);
  // background-size: cover;
  // background-size: calc(100% * (16 / 9)) 100%;
  // background-size: 960px 560px;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  .img_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../imgs/bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    // .bg_sty {
    //   display: block;
    //   margin: auto;
    //   width: 100%; /* 或者设置为你需要的具体宽度 */
    //   height: auto; /* 保持图片的纵横比 */
    // }

    .itemgraph_device {
      height: 24px;
      background: rgba(0,0,0,0.5);
      border-radius: 2px;
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover{
        background: rgba(0,0,0,0.8);
      }
    }
    .gateway_meter {
      top: 16.2%;
      left: 21.7%;
    }
    .cabinet {
      top: 21.5%;
      left: 21.7%;
    }
  
    .combiner {
      top: 27.2%;
      left: 21.7%;
    }
  
    .inverter1 {
      top: 34.9%;
      left: 21.5%;
    }
  
    .inverter2 {
      top: 34.9%;
      left: 28.2%;
    }
  
    .inverter3 {
      top: 34.9%;
      left: 35%;
    }
  
    .inverter4 {
      top: 34.9%;
      left: 41.8%;
    }
    .inverter5 {
      top: 34.9%;
      left: 48.8%;
    }
  
    .inverter6 {
      top: 34.9%;
      left: 57.2%;
    }
    .inverter7 {
      top: 42.9%;
      left: 57.2%;
    }
    .inverter8 {
      top: 50.8%;
      left: 57.2%;
    }
    .inverter9 {
      top: 58.8%;
      left: 57.2%;
    }
    .inverter10 {
      top: 69%;
      left: 36.2%;
    }
    .robot {
      top: 69%;
      left: 21.7%;
    }
  
    .img {
      width: 24px;
      height: 24px;
    }
    .label {
      font-weight: 500;
      font-size: 12px;
      // color: #52E7FF;
      padding: 2px 4px;
    }
    .labelExpand {
      max-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  // .bg_sty {
  //   margin-top: 20px;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: auto;
  //   object-fit: contain; /* 确保图片不失真 */
  // }
  
  .device {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    .item_device {
      width: 180px;
      max-height: 200px;
      background-color: transparent;
      backdrop-filter: blur(10px);
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      padding: 8px;
      flex-direction: column;
      display: flex;
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(82, 231, 255, 0.5), rgba(82, 231, 255, 0.1), rgba(82, 231, 255, 0.5), rgba(39, 150, 137, 1)) 1 1;
      }
      .title_box {
        display: flex;
        align-items: center;
        padding-bottom: 4px;
        border-bottom: 1px dashed #FFFFFF20;
        margin-bottom: 4px;
        .alert {
          color: #ec2020;
          margin-right: 4px;
          .icon {
            position: relative;
            &::before {
              @extend %animation-share;
              animation: wave 2s ease-in-out 0.4s infinite normal none;
            }
        
            &::after {
              @extend %animation-share;
              animation: wave 2s ease-in-out 1.6s infinite normal none;
            }
          }
        }
        .title_word {
          color: rgba(255,255,255,0.85);
          font-weight: 500;
          font-size: 12px;
        }
        .red {
          color: #E64242;
        }
      }
      .dataPropsBox {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: #ffffff40;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }
        &::-webkit-scrollbar-corner{
          background: #ffffff40;
        } 
        .itemDataProp {
          display: flex;
          align-items: center;
          .label {
            width: 76px;
            font-size: 12px;
            color: rgba(255,255,255,0.65);
          }
          .value {
            width: 0px;
            flex: 1;
            font-weight: 500;
            font-size: 12px;
            color: rgba(255,255,255,0.85);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .green {
            color: #52C41A;
          }
          .yellow {
            color: #FFCB47;
          }
          .blue {
            color: #52E7FF;
          }
        }
      }
    }
  }
  
  .bg_sty {
    // position: absolute;
    // left: 0;
    // top: 20px;
    // max-width: 960px;
    // width: 100%;
    max-width: 100%;
    height: auto;
    // height: 540px;
  }
}


@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(0.2);
    opacity: 0.2;
  }
  20% {
    transform: scale(0.4);
    opacity: 0.4;
  }
  30% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  40% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  60% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  80% {
    transform: scale(1.6);
    opacity: 0.4;
  }
  90% {
    transform: scale(1.6);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}