.title {
  margin-right: 30px;
}
.cardBoxSty {
  height: 0px;
}

.bottomStyles {
  flex: 1;
  width: 0px;
  .tabsStyle {
    width: 100%;
    :global {
      .ant-tabs-nav {
        margin-bottom: 3px;
      }
    }
  }
}

.table {
  flex: 11;
  height: 0;
  :global {
    .ant-table {
      background:transparent !important
    }
    .ant-table-thead > tr > th{
      padding: 2px !important;
      background: transparent;
      &::before {
        width: 0px !important;
      }
    }
    .ant-table-thead > tr > td{
      padding: 2px !important;
      background: transparent;
      &::before {
        width: 0px !important;
      }
    }
    .ant-table-tbody > tr > td{
      padding: 2px !important;
    }

    .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
      background: transparent !important;
    }
    .ant-table-tbody{
      > tr:hover:not(.ant-table-expanded-row) > td,.ant-table-row-hover,.ant-table-row-hover>td{
       background:#ffffff10!important;
      }
    }
  }
}
.chart_box {
  flex: 14;
  height: 0;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 2px;
  display: inline-block;
}
.word {
  font-size: 12px;
  line-height: 20px;
}
.abnormal_circle {
  background: #E64242;
}
.abnormal_word {
  color: #E64242;
}

.poor_circle {
  background:#FAAD14
}
.poor_word {
  color: #FAAD14;
}

.general_circle {
  background:#4A90E2
}
.general_word {
  color:#4A90E2
}

.excellent_circle {
  background: #52C41A;
}
.excellent_word {
  color: #52C41A;
}

.noData_circle {
  background: rgba(255,255,255,0.65);;
}
.noData_word {
  color: rgba(255,255,255,0.65);;
}

