.sort {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #00adff;
}

.sortTd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addPCS {
  margin-bottom: 15px;
}
