.cardContainer {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;

  .cardItem {
    flex: 1;
    margin-right: 9px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.layout {
  min-height: calc(100vh - 130px);
  background: none;
}

.content {
  background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;

  .toggleBtn {
    // background-color: skyblue;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.sider {
  border-radius: 2px;
  transition: all 0.2s;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tabContent {
  padding: 0 12px;
}

.chartContainer {
  position: relative;
}

.fullScreen {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
  z-index: 99;

  &:hover {
    color: skyblue;
  }
}

.block {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.item {
  padding-left: 24px;
  height: 24px;
  position: relative;
  font-size: 12px;

  .pipe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 16px;
  }
}
