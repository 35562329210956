.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepWrap {
  margin-top: 28px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;

  .step {
    width: 885px;
  }
}
