.wrapper {
  height: 100%;
  padding: 0px 10px 6px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  .itemCard {
    width: 139px;
    height: 124px;
    background: rgba(74,144,226,0.1);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    .top_title {
      width: 100%;
      height: 32px;
      background: rgba(74,144,226,0.1);
      border-radius: 2px 2px 0px 0px; 
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 2px;
      position: relative;
      .icon {
        width: 16px;
        height: 16px;
      }
      .title_label {
        font-weight: 500;
        color: rgba(255,255,255,0.85);
      }
      .img_line {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
      }
    }
    .bottom_content {
      flex: 1;
      height: 0px;
      width: 100%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item_title {
          font-size: 12px;
          color: rgba(255,255,255,0.65);
        }
        .item_value {
          font-weight: 500;
        }
        .total_number {
          color: rgba(255,255,255,0.85);
        }
        .finished_number {
          color: #52C41A;
        }
        .pedding_number {
          color: #FFCB47;
        }
      }
    }
  }
}