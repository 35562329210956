.RightInfoTitle {
  height: 32px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  border-left: 2px solid #4a90e2;
}

.RightInfoItemElec {
  border: 1px solid #4a90e2;
  border-radius: 2px;
  // height: 96px;
  margin: 10px 12px;
  padding: 8px;
  .itemLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    .itemLineImgTitle {
      display: flex;
      align-items: center;
    }
    .monthTitle {
      font-size: 14px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
    }
    .chargeValueStyle {
      color: #57fb8b;
    }
    .disChargeValueStyle {
      color: #12c6ff;
    }
  }
}
