.RightInfoTitle {
  height: 32px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border-left: 2px solid #4a90e2;
}

.relatedPartyMaint {
  :global {
    .ant-table-tbody > tr > td {
      padding: 0px !important;
      line-height: 40px !important;
    }
  }
}
