.wrapper{
  width: 100%;
  height: 100%;
  .top_box {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    .weather_box {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .weather{
        font-weight: 500;
        font-size: 20px;
        color: rgba(255,255,255,0.85);
      }
      .detail {
        font-size: 12px;
        color: rgba(255,255,255,0.65);
      }
    }
    .radiation{
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-top: 8px;
      .label{
        font-size: 12px;
        color: rgba(255,255,255,0.65);
      }
      .value {
        font-size: 12px;
        color: rgba(255,255,255,0.85);
      }
    }
  }
  .h_divider{
    width: 100%;
    margin: auto;
    height: 1px;
    background: rgba(255,255,255,0.1);
  }

  .bottom_box {
    height: calc(100% - 65px);
    width: 100%;
    display: flex;
    align-items: center;
    .alarm_box {
      width: 111px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
      .alarm_number {
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        background: rgba(230,66,66,0.4);
        border-radius: 2px;
        margin-bottom: 7px;
        padding: 0 8px;
      }
      .label{
        font-size: 12px;
        color: rgba(255,255,255,0.65);
      }
    }
    .v_divider {
      height: 100%;
      width: 1px;
      background: rgba(255,255,255,0.1);
    }
    .power_box {
      width: calc(100% - 112px);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .title{
        width: calc(100% - 64px);
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 64px;
        margin-bottom: 4px;
        .label{
          font-size: 12px;
          color: rgba(255,255,255,0.65);
          line-height: 20px;
        }
      }
      .line_box {
        width: 100%;
        display: flex;
        align-items: center;
        .label {
          width: 56px;
          text-align: center;
          font-size: 12px;
          color: rgba(255,255,255,0.65);
          line-height: 20px;
        }
        .total_value {
          width: 106px;
          height: 24px;
          background: rgba(255,203,71,0.1);
          border-radius: 2px;
          font-weight: 500;
          color: #FAAD14;
          line-height: 24px;
          text-align: center;
          margin-right: 4px;
        }
        .charge_value {
          width: 106px;
          height: 24px;
          background: rgba(22,221,142,0.1);
          border-radius: 2px;
          font-weight: 500;
          color: #16DD8E;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}