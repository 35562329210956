.wrapperPadding {
  padding: 20px;
  min-height: calc(100vh - 128px);
}
.log_box {
  height: 400px;
  overflow-y: auto;
}

.modalTip {
  margin-left: 24px;
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--mx-text-desc-color);
}
