.wrap {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.contentWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  height: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #2a2a2a 0%, #1f1f1f 100%);
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.12), 0px 9px 28px 0px rgba(0, 0, 0, 0.2),
    0px 6px 16px -8px rgba(0, 0, 0, 0.32);
  border-radius: 2px;

  .topLine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #057abf 0%, #2cc4f9 100%);
  }

  .loadingImg {
    width: 40px;
    height: 40px;
    margin-top: 28px;
    margin-bottom: 8px;
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
