.tag {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border: 1px solid rgba(81, 217, 238, 0.3);
  font-size: 12px;
  line-height: 20px;
  padding-left: 7px;
  padding-right: 7px;
}
