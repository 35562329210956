.searchArea {
  // padding: 0 10px;
  padding-bottom: 20px;
  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}
.tree_outer {
  height: calc(100vh - 350px);
  margin: 20px 0;
  background: var(--mx-module-bg-color);
  overflow-y: auto;
  width: 350px;
  .tree_area {
    padding: 20px 0;
  }
}

.wrapperPadding {
  padding: 20px;
}
