.top {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 6px;
  .item_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, rgba(74,144,226,0.1) 100%);
    padding: 8px 27px;
    position: relative;
    .value {
      max-width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 16px;
      color: #52E7FF;
      line-height: 24px;
    }
    .label{
      font-size: 12px;
      color: rgba(255,255,255,0.65);
      line-height: 20px;
    }
    .img_line {
      position: absolute;
      bottom: 0;
      width: 100px;
      height: 3px;
    }
  }
}

.line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  .line_dash {
    height: 1px;
    width: calc((100% - 70px) / 2);
    border-top: 1px dashed  rgba(255,255,255,0.2);
  }
  .line_text {
    width: 70px;
    font-weight: 500;
    font-size: 14px;
    color: rgba(255,255,255,0.85);
    line-height: 22px;
    text-align: center;
  }
}

.power_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  .power {
    flex: 1;
    height: 32px;
    background: linear-gradient( 276deg, rgba(255,246,77,0.2) 0%, rgba(255,210,49,0.3) 100%);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
  }
  .charge {
    flex: 1;
    height: 32px;
    background: linear-gradient( 276deg, rgba(77,255,197,0.2) 0%, rgba(49,177,255,0.3) 100%);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
  }
  .label {
    font-weight: 500;
    color: rgba(255,255,255,0.85);
    line-height: 22px;
    position: relative;
    .yellow_circle {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 24px;
      height: 24px;
      animation: rotateImage 10s linear infinite;
      z-index: 1;
    }
    .power_icon {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 16px;
      height: 16px;
      z-index: 2;
    }
    .desc {
      margin-left: 28px;
    }
  }
  .value {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 24px;
  }
}

.area_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 9px;
  gap: 8px;
  .each_box {
    flex:1;
    height: 100%;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .bottom_top {
      width: 100%;
      height: 26px;
      background: #112A4D;
      padding-left: 9px;
      line-height: 26px;
      font-weight: 500;
      color: rgba(255,255,255,0.85);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
    .bottom_bottom {
      width: 100%;
      flex: 1;
      background: #0B2140;
      display: flex;
      flex-direction: column;
      padding: 3px 8px;
      justify-content: space-evenly;
      .label {
        font-size: 12px;
        color: rgba(255,255,255,0.65);
        line-height: 20px;
        text-align: center;
      }
      .power_value {
        font-weight: 500;
        color: #FFCB47;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }
      .charge_value {
        font-weight: 500;
        color: #52E7FF;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}