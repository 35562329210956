.wrapper {
  padding: 0;
  background: none;
}

.layout {
  min-height: calc(100vh - 128px);
  background-color: transparent;
}

.content {
  background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  .top_box{
    margin: 20px;
    background-color: #2D2D2D;
    border-radius: 2px;
    height: 80px;
  }

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .refreshTimeSty {
    position: absolute;
    top: 100px;
    right: 30px;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.65);
    line-height: 20px;
  }
}

.sider {
  border-radius: 2px;
  transition: all 0.2s;
}

.tabContent {
  padding: 0px 20px 20px;
  :global {
    .ant-tabs-top >.ant-tabs-nav {
      margin: 0 0 27px 0;
    }
  }
}

.legendSty {
  position: absolute;
  top: 140px;
  right: 30px;
  display: flex;
  align-items: center;
  .eachLegendSty {
    display: flex;
    align-items: center;
    .imgSty{
      width: 17px;
      height: 14px;
      margin-right: 4px;
      margin-left: 24px;
    }
  }
}