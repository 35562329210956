.tabs {
  width: 100%;
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  .tabDesc {
    font-size: 12px;
    margin-top: 2px;
  }
}
:global {
  .tabs {
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: none;
    }
  }

  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-top: 0px;
  }
}
