.ColSty {
  background: #232324;
  padding: 10px;
  min-height: 258px;
  :global {
    .ant-spin-nested-loading {
      width: 100%;
    }
  }
  .echaRowSty {
    margin: 8px 0px;
  }
  // margin-right: 10px;
  .Alarm {
    // width: 84px;
    height: 100px;
    min-height: 90px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    // margin: 4px;
    .AlarmTitle {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      line-height: 20px;
    }
    .AlarmValue {
      font-size: 28px;
      font-weight: 500;
      line-height: 40px;
    }
  }
}
