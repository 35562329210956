.ProjectaddressStyle {
  min-height: 71px;
  min-width: 131px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 2px;
  .eachProinfo {
    display: flex;
    align-items: center;
    .imgSty {
      width: 14px;
      height: 14px;
      margin: 0px 2px;
    }
    .persenSty {
      display: inline-block;
      width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 17px;
    }
  }
  .eachAddress {
    display: flex;
    margin-top: 5px;
    .imgSty {
      width: 14px;
      height: 14px;
      margin: 0px 2px;
    }
    .addressSty {
      width: 128px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 17px;
    }
  }
  :global {
    .ant-divider-horizontal {
      margin: 2px 0;
    }
  }
}
.ColSty {
  background: #232324;
  padding: 10px;
  min-height: 210px;
  // margin-right: 10px;
}
