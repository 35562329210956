.search {
  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}

.ListInfo {
  // height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

.Item {
  padding: 0px 10px 10px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  cursor: pointer;
  &.active {
    background: rgba(64, 64, 64, 0.39);
    border-left: solid 2px rgba(0, 173, 255, 0.61);
  }
  &:last-of-type {
    border-bottom-width: 0;
  }
  :global {
    .ant-row {
      padding-top: 12px;
    }
  }
}

.ItemNameStyle {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.ItemInfoStyle {
  color: #00adff;
  font-size: 12px;
  line-height: 17px;
}
