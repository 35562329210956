.CollapseStyle {
  opacity: 0.9;
  width: 280px;
  padding: 10px;
  border-radius: 4px;
  .RightInfoStyle {
    height: calc(100vh - 224px);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff30;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  :global {
    .ant-collapse-header {
      background-color: #202020;
    }
    .ant-collapse-expand-icon {
      position: absolute;
      right: 1px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
    .ant-collapse-content {
      border-top: 0;
    }
    .ant-collapse {
      border: 0;
    }
    .ant-collapse > .ant-collapse-item {
      border: 0;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 10px 16px;
    }
  }
}
