.wrap {
}

.topFilter {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.elecStatistics {
  margin-bottom: 16px;

  .cardWrap {
    display: flex;
    gap: 8px;

    .card {
      flex: 1;
      height: 64px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 16px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;

      .splitLine {
        width: 1px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: '';
          width: 1px;
          height: 40px;
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .label {
        margin-right: 16px;
        color: var(--mx-text-secondary-color);
      }
    }
  }
}
