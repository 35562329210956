.wrapper {
  padding: 0;
  background: none;
}

.layout {
  min-height: calc(100vh - 128px);
  background-color: transparent;
}

.content {
  background: var(--mx-module-bg-color);
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  padding: 20px;
  .top_box {
    margin: 24px 0px 16px;
    border-radius: 2px;
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.CollapseStyle {
  min-height: 88px;
  margin-bottom: 12px;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  .coll_text_sty {
    color: #3d9ce9;
    font-size: 14px;
  }
  :global {
    .ant-collapse-item {
      height: 100%;
      border-bottom: none;
    }
    .ant-collapse-header {
      height: 100%;
      background: rgba(255, 255, 255, 0.05);
    }
    .ant-collapse-expand-icon {
      position: absolute;
      right: 24px;
      bottom: 30px;
      z-index: 999;
      height: 24px;
    }
    .ant-collapse-header-text {
      width: 100%;
    }
    .ant-collapse-content {
      color: rgba(255, 255, 255, 0.85);
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 20px 38px;
      // background: rgba(255,255,255,0.05);
      // border: 1px solid rgba(255,255,255,0.1);
    }
    .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      font-size: 14px;
      color: var(--mx-btn-link-color);
      line-height: 24px;
      -webkit-font-smoothing: auto;
    }
  }
}
