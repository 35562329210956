.wrap {
  padding: 0 20px;
}

.top {
  width: 100%;
  display: flex;
  margin-bottom: 17px;
}

.export {
  margin-left: auto;
}
