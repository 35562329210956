.wrap {
  padding: 0 20px;
}

.top {
  width: 100%;
  display: flex;
  margin-bottom: 17px;

  .ctrlWrap {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.export {
  margin-left: auto;
}

.deviceCell {
  margin-left: 16px;
}

.descInfo {
  margin-right: 10px;
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
