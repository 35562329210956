$component-background: var(--component-background);

.page {
  padding: 8px 13px 8px;
  position: relative;
  background-color: #29292c;
}

.page::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.svg');
  opacity: 0.6;
}

.breadcrumb {
  padding: 0px 30px;
}

.layout {
  min-height: calc(100vh - 102px);
  background-color: transparent;
}

.content {
  // background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
  .filter {
    height: 7vh;
    background: #232324;
    display: flex;
    align-items: center;
    padding: 6px 20px;
  }
  .Info {
    background: #232324;
    padding: 6px 20px;
    .DashBoardStyle {
      height: 100px;
      border-top: 1px solid #ffffff10;
      border-left: 1px solid #ffffff10;
      border-bottom: 1px solid #ffffff10;
      background-color: #39393a;
      .TopItemInfo {
        display: flex;
        align-items: center;
        border-right: 1px solid #ffffff10;
        padding: 17px 16px;
        .ItemInfoImg {
          width: 69px;
          height: 60px;
        }
        .TopLabel {
          font-size: 14px;
          line-height: 17px;
          padding-bottom: 4px;
        }
        .TopValue {
          font-size: 24px;
          font-weight: 500;
          color: #fff;
        }
        .upperLowPercent {
          margin-left: 6px;
        }
      }
    }
  }
}

.sider {
  border-radius: 2px;
  transition: all 0.2s;
}

.selectedOuTitle {
  height: 7vh;
  background: #232324;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  .titleDetails {
    display: flex;
    align-items: center;
  }
  .ouNameSty {
    display: inline-block;
    width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  .imgSty {
    width: 14px;
    height: 14px;
    margin: 0px 2px;
  }
}

.bottomContent {
  background: #232324;
}
