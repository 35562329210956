.chart_box {
  width: 100%;
  height: 320px;
  .spin_box {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .empty_box {
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
