.row {
  margin: 12px 0;
}

.label {
  position: relative;
  min-width: 90px;
  text-align: right;
  display: inline-block;
  color: #ffffff85;
  text-align: left;
  &:after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ac222a;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.compareModalBody {
  min-height: 560px;
  display: flex;

  .leftCol {
    width: 292px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding-right: 20px;
  }

  .rightCol {
    flex: 1;
    padding-left: 20px;
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #4a90e2;
  }

  :global {
    .ant-divider-horizontal.ant-divider-with-text {
      color: #1e5a7c;
    }
    .ant-divider {
      position: relative;

      .ant-divider-inner-text {
        padding: 0 14px;
        color: #4a90e2;
        font-size: 14px;
      }
    }
  }
}

.customSelect {
  padding: 10px 0;
}

.btnArea {
  margin-top: 20px;
  text-align: center;
}

.dateSwitch {
  :global {
    .ant-radio-button-wrapper:first-child {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
    .ant-radio-button-wrapper {
      border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: transparent;
    }
  }
  .datePickerArea {
    margin-left: 40px;
    display: inline-flex;

    .label {
      position: relative;
      display: inline-flex;
      align-items: center;

      &:after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }
  }
}

.compareSection {
  padding: 20px 0;
}
