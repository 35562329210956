.wrapper {
  padding: 20px;
  .btn_show_box {
    padding: 0px 32px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .show_value_box {
      height: 32px;
      background: var(--mx-base-bg-color);
      border-radius: 2px;
      padding: 5px 16px;
      display: flex;
      align-items: center;
      .label {
        color: var(--text-color-secondary);
      }
      .value {
        color: #16dd8e;
      }
    }
  }
  .table_wrapper {
    padding: 0px 32px;
  }
}
