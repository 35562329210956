.border-wrapper {
  border-style: solid;
  border-width: 140px 180px;
  border-image-source: url('../../assets/images/border.svg');
  border-image-slice: 140 180;
  border-image-repeat: repeat;

  &-inner {
    margin: -123px -163px -122px -163px;
    position: relative;
    overflow: hidden;
  }
}
