.page_box {
  :global {
    .page__content {
      min-height: calc(100vh - 128px);
    }
  }
}
.control_area {
  display: flex;
  background: #2d2d2d;
  border-radius: 2px;
  padding: 11px 16px;
  min-height: 64px;
  .control_area_inner {
    display: flex;
    align-items: center;
    .control_title {
      display: flex;
      align-items: center;
      .control_title_img {
        width: 24px;
        margin-right: 8px;
        img {
          width: 100%;
        }
      }
    }
    .control_line {
      width: 1px;
      height: 50%;
      background: rgba(255, 255, 255, 0.1);
      margin: 0 16px;
    }
    .control_buttons {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      gap: 10;
      align-items: center;
      .button_box {
        margin: 5px 5px 5px 0;
      }
    }
  }
}
.table_title {
  padding: 16px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
