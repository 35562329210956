.topCardWrapper {
  padding: 12px 20px;
  display: flex;
  width: 100%;
  height: 100%;
  .topCard_nameSty {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    // align-items: center;
    border-right: 1px solid rgba(255,255,255,0.1);
    .showCluster {
      display: flex;
      justify-content: flex-start;
      margin-left: 16px;
    }
    .imgSty{
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .nameSty {
      width: 90%;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: rgba(255,255,255,0.85);
      line-height: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .topCard_rightSty {
    width: 70%;
    height: 60%;
    display: flex;
    flex-direction: column;

    .jumpHighAndLowSty {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255,255,255,0.65);
      line-height: 22px;
    }

    .topCard_rightSty_topShow {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      .topCard_volSty {
        width: calc(50% + 24px);
        min-width: 280px;
        border-right: 1px solid rgba(255,255,255,0.1);
        display: flex;
        // justify-content: space-around;
        padding-left: 24px;
        align-items: center;
        .lowVSty {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .imgSty {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
        .titleSty {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(255,255,255,0.65);
          line-height: 22px;
          margin-right: 8px;
        }
        .valueSty {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(255,255,255,0.85);
          line-height: 22px;
        }
      }
    
      .topCard_temSty {
        width: 50%;
        min-width: 255px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .imgSty {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
        .titleSty {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(255,255,255,0.65);
          line-height: 22px;
          margin-right: 8px;
        }
        .valueSty {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(255,255,255,0.85);
          line-height: 22px;
        }
      }
    }
  
  }

  
}