.wrap {
  margin-top: 16px;
  height: calc(100% - 16px);

  :global {
    .ant-spin-container {
      height: 100%;
    }
  }
}

.chart {
  height: 100% !important;
}

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
