.cardContainer {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  .cardItem {
    flex: 1;
    margin-right: 9px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.layout {
  min-height: calc(100vh - 123px);
  background: transparent;
}

.content {
  background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  padding: 20px;
}

.sider {
  border-radius: 2px;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tabContent {
  padding: 0 12px;
}

.chartContainer {
  position: relative;
}

.sectionTitle {
  font-size: 16px;
  color: var(--text-color);
  padding-left: 11px;
  line-height: 24px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background: var(--primary-color);
    width: 4px;
    height: 16px;
    left: 0;
    top: 4px;
  }
}

.picker {
  display: flex;
  align-items: center;
  margin-top: 22px;

  .datePickerArea {
    margin-left: 48px;
  }
}

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;

  &:after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}
