.lineTitle {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.65);
  line-height: 24px;
  padding-left: 10px;
  width: 100%;
  .titleBorder {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 4px;
    height: 20px;
    background-color: var(--primary-color);
  }
}
