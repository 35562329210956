.filterArea {
  padding: 0 10px;
  margin-bottom: 12px;
}

.filterArea,
.searchArea {
  padding: 0 10px;
  margin-bottom: 12px;

  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}

.filterArea {
  padding-top: 18px;
  display: flex;
  align-items: center;

  .label {
    flex: 1;
  }
}

.treeStyle {
  // height: calc(100vh - 260px);

  //   /* 1,滚动条 */
  //   ::-webkit-scrollbar {
  //   width: 10px;
  //   /* 纵向滚动条 宽度 */
  //   height: 5px;
  //   /* 横向滚动条 高度 */
  //   background: yellow;
  //   /* 整体背景 */
  //   border-radius: 10px;
  //   /* 整体 圆角 */
  //   }

  //   /* 滚动条滑块 */
  // ::-webkit-scrollbar-thumb {
  //   border-radius:10px;
  //   background:red;
  //   // -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
  //   }
}

.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .treeBox {
    flex: 1;
    overflow: auto;
    z-index: 1;
  }
  :global {
    .ant-tree-switcher {
      line-height: 32px;
      color: var(--primary-color);
    }

    .measurement-title {
      // border-top: 1px solid rgba(255, 255, 255, 0.12);
      padding-top: 10px;
      margin-top: 6px;
    }

    .measurement-title:first-child {
      border-top: 0 none;
    }

    .cabinet-title {
      .ant-tree-switcher {
        text-align: right;
        margin-left: -10px;
      }
    }

    .circuit-title {
      transition: all 0.3s;

      &:hover {
        // background-color: rgba(64,64,64,.8);
      }

      .ant-tree-switcher {
        margin-left: -10px;
      }
    }

    .ant-tree .ant-tree-node-content-wrapper {
      padding: 4px;
    }

    .ant-tree .ant-tree-node-content-wrapper:hover {
      background-color: transparent;
    }

    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: transparent;
    }

    .ant-tree-treenode-selected {
      background-color: rgba(64, 64, 64, 1);
      position: relative;

      &:before {
        content: '';
        width: 2px;
        height: 100%;
        background-color: #00adff;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .ant-spin-nested-loading {
      height: 100px;
    }

    .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
      flex: none;
      display: flex;

      &:hover {
        background-color: rgba(64, 64, 64, 0.8);
      }
    }

    .ant-tree-list .ant-tree-list-holder {
      // height: calc(100vh - 260px);
      // padding: 0 5px 0 8px;
    }

    .ant-tree-list-holder {
      overflow: auto;
    }

    .ant-tree .ant-tree-treenode {
      padding: 0;
      margin-bottom: 4px;
    }

    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: rgba(64, 64, 64, 1);
    }
    .ant-tree-switcher .ant-tree-switcher-icon,
    .ant-tree-switcher .ant-select-tree-switcher-icon {
      margin-left: 10px;
    }
  }
}

.filterTxt {
  color: #e64242;
}
