.contentWrapper {
  padding: 10px 10px 20px 30px;
  .divider {
    margin-bottom: 16px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .content {
  }
}
