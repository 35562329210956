.title {
  margin-right: 30px;
}
.cardBoxSty {
  height: 0px;
}

.bottomStyles {
  flex: 1;
  width: 0px;
  .tabsStyle {
    width: 100%;
    :global {
      .ant-tabs-nav {
        margin-bottom: 3px;
      }
    }
  }
}

.content_wrapper {
  height: 100%;
  display: flex;
  .left_card_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0px;
    .item_card {
      flex: 1;
      width: 120px;
      border-radius: 2px;
      position: relative;
      .img {
        width: 64px;
        height: 96px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .label {
        font-weight: 500;
        color: rgba(255,255,255,0.85);
        line-height: 22px;
        position: absolute;
        top: 12px;
        left: 12px;
      }
      .value {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        position: absolute;
        bottom: 12px;
        left: 12px;
        right: 8px;
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  
  .right_table_box {
    flex: 1;
    margin: 16px 16px 10px;
    width: 0;
    :global {
      .ant-table {
        background:transparent !important
      }
      .ant-table-body {
        scrollbar-width: auto;
        scrollbar-color: auto;
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background: #ffffff40;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 4px;
        }
        &::-webkit-scrollbar-corner{
          background: #ffffff40;
        } 
      }
      .ant-table-thead > tr > th{
        background: rgba(74,144,226,0.2);
      }
      .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
        background: transparent !important;
      }
      .ant-table-tbody{
        > tr:hover:not(.ant-table-expanded-row) > td,.ant-table-row-hover,.ant-table-row-hover>td{
         background:#ffffff10!important;
        }
      }
    }
  }
}