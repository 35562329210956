.desc {
  width: 600px;
  padding: 10px;
  &.background {
    background-color: #293441;
    margin-bottom: 15px;
  }
}
.setIot,
.optionsBtn {
  color: var(--primary-color);
  text-align: center;
  cursor: pointer;
}
.optionsBtn {
  padding-right: 10px;
}
