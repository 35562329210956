$component-background: var(--component-background);
.cardContainer {
  overflow-x: auto;
  // white-space: nowrap;
  // padding-bottom: 10px;
  .cardItem {
    margin-right: 9px;
    width: 260px;
    height: 88px;
    display: inline-block;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.cardContainerMoreThanTree {
  display: flex;
  padding-bottom: 10px;
  overflow-x: auto;
  .cardItem {
    margin-right: 9px;
    flex: 1;
    min-width: 248px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.wrapper {
  padding: 0;
  background: none;
}

.layout {
  min-height: calc(100vh - 150px);
  background-color: transparent;
}

.content {
  // background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.sider {
  border-radius: 2px;
  transition: all 0.2s;
}

.filter {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
  // flex-wrap: wrap;
}
.commonBtn {
  float: right;
  margin-left: 10px;
}
.tabContent {
  padding: 20px;
}

.chartContainer {
  position: relative;
  height: calc(100vh - 400px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sortBtn {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab_box {
  display: flex;
  border-bottom: 1px solid #303030;
  height: 47px;
  // margin-bottom: 10px;
}

.spanCursor {
  color: #4a90e2;
  margin: 5px 0 0 10px;
  cursor: pointer;
  display: inline-block;
}
// @media only screen and(max-width:1200px) {
//   .hidden {
//     display: none !important;
//   }
// }
.position_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.currentSelected {
  margin-bottom: 12px;
  .currentSelectedName {
    font-size: 14px;
    color: #fff;
    margin-right: 12px;
  }
}

.selectedOuTitle {
  height: 45px;
  background: #232324;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  .ouNameSty {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
}
