.alarmTable {
  :global {
    .ant-table-sticky-scroll {
      display: none;
    }
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: flex-end;
  align-items: center;

  .total {
    // margin-right: 20px;
    color: var(--text-color);
  }
}

.wrapperPadding {
  padding: 20px;
}

.pageContent {
  padding: 20px;
  min-height: calc(100vh - 400px);
  background-color: var(--component-background);
}
