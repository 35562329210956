.wrap {
  width: 720px;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topStatistics {
  height: 120px;
  background: rgba(74, 144, 226, 0.05);
  border-radius: 2px;
  margin-bottom: 16px;

  .titleWrap {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 25px 16px;

    &::before {
      content: '';
      width: 239px;
      height: 1px;
      border: 1px dashed rgba(74, 144, 226, 0.5);
    }

    &::after {
      content: '';
      width: 239px;
      height: 1px;
      border: 1px dashed rgba(74, 144, 226, 0.5);
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 22px;
      background: url('../images/todayDataTitle.png');
      background-size: 100% 100%;
      font-weight: 500;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
  }

  .detailRow {
    display: flex;

    .detailItem {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .row {
        display: flex;
        width: 100%;
        gap: 16px;
      }
      .rowLabel {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
        width: 56px;
      }

      .value {
        font-weight: 500;
        font-size: 16px;
        color: #16dd8e;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .label {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
      }

      .progressContainer {
        width: 100%;
        display: flex;
        column-gap: 8px;
        flex: 1;
      }

      .progress {
        :global {
          .ant-progress-text {
            color: var(--mx-text-base-color);
          }
        }
      }
    }

    .more {
      flex: 2;
      padding: 0 15px;
    }
  }
}

.targetCompletion {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 155px;
  background: rgba(74, 144, 226, 0.05);
  border-radius: 2px;

  .chartWrap {
    position: relative;
    width: 88px;
    height: 88px;
    margin-right: 16px;

    .label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .value {
      font-weight: 500;
      font-size: 16px;
      color: #ffcb47;
    }
  }
}

.elecChart {
  height: 100% !important;
  width: 100%;
}

.divider {
  border-color: var(--mx-base-border-color);
  height: 48px;
  margin-top: 4px;
  margin-right: 0;
  margin-left: 0;
}
