.wrapper {
  min-height: calc(100vh - 128px);
}

.DashBoardStyle {
  height: 100px;
  border-top: 1px solid #ffffff10;
  border-left: 1px solid #ffffff10;
  border-bottom: 1px solid #ffffff10;
  background-color: #39393a;
  .DashBoardColStyle {
    border-right: 1px solid #ffffff10;
    padding: 17px 16px;

    :global {
      .ant-statistic .ant-statistic-title {
        color: var(--mx-text-secondary-color);
      }
    }
  }
}

.typeChangeStyle {
  position: absolute;
  right: 20px;
  top: 140px;
}

.pageFilter {
  background: var(--component-background);
  padding-top: 20px;
}
.dividerSty {
  :global {
    .ant-divider-horizontal {
      margin: 0;
    }
  }
}
