.contentWrapper {
  margin-left: 30px;
  margin-right: 10px;
  margin-bottom: 30px;
  .divider {
    color: rgba(255, 255, 255, 0.8);
    &::before {
      width: 0 !important;
    }
  }
  .content {
    padding-left: 40px;
  }
}
