.ColSty {
  background: #232324;
  padding: 10px 20px;
  height: 570px;
  .ColContent {
    border: 1px solid #ffffff20;
    padding: 8px 10px;
  }
}

.TableSty {
  height: calc(58vh - 234px);
  overflow-y: auto;
  :global {
    .ant-empty-normal {
      margin: 0;
    }
  }
}
.stationNameBtn {
  padding: 0;
  font-size: 12px;
  height: 0;
  display: flex;
  align-items: center;
}

.stationNameSty {
  display: inline-block;
  width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
