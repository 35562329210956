.btn {
  position: relative;
  display: inline-block;
  :global {
    .ant-btn {
      border: none;
      background: rgba(255, 255, 255, 0.08);
      color: rgba(255, 255, 255, 0.65);
    }
    .ant-btn-link {
      color: #4a90e2;
      border-color: transparent;
      background: transparent;
      box-shadow: none;
    }
    .ant-btn-link:disabled {
      color: #999;
    }
    .ant-btn-primary {
      color: #fff;
      background: var(--gradient-background);
      box-shadow: 0px 7px 13px 0px rgba(44, 196, 249, 0.37);
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      font-weight: 600;
      border: none;
      padding: 4px 19px;
      position: relative;
      &:hover,
      &:focus {
        background: var(--gradient-background);
      }
    }
  }
  .line {
    position: absolute;
    opacity: 0.5;
    border-radius: 1px;
    width: 2px;
    top: 0;
    height: 100%;
    background: white;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  .dot {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 2px;
    background: white;
    opacity: 0.5;
    &--top-left {
      top: 2px;
      left: 5px;
    }
    &--top-right {
      top: 2px;
      right: 5px;
    }
    &--bottom-left {
      bottom: 2px;
      left: 5px;
    }
    &--bottom-right {
      bottom: 2px;
      right: 5px;
    }
  }
}
