.disable {
  color: #666;
  cursor: not-allowed;
  pointer-events: none;
  display: inline-block;
}
.active {
  color: var(--primary-color);
  cursor: pointer;
  pointer-events: initial;
  display: inline-block;
}
.pad_t_5 {
  padding-top: 5px;
}

.wrapperTabsPadding {
  padding: 20px;
  min-height: calc(100vh - 128px);
}
