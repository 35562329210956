.tabSty {
  :global {
    .ant-tabs-nav-wrap{
      border-bottom: 1px solid #303030
    }
  }
} 

.item_wrapper_title {
  display: flex;
  align-items: center;
  .imgSty{
    width: 16px;
    height: 16px;
    margin-right: 3px;
  }
  .nameSty {
    width: 300px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(255,255,255,0.85);
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.item_wrapper_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0px 0px 8px;
  .item_wrapper_eachContent {
    cursor: pointer;
    position: relative;
    width: 120px;
    height: 64px;
    margin: 0px 8px 8px 0px;
    // background: rgba(74,144,226,0.2);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
    .titleSty {
      display: flex;
      align-items: center;
      .imgSty{
        width: 17px;
        height: 14px;
        margin-right: 4px;
      }
      .nameSty {
        width: 70px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(255,255,255,0.65);
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .valueSty {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: rgba(255,255,255,0.85);
      line-height: 24px;
    }

    .maxIcon {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 26px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #E64242;
      line-height: 20px;
      background: rgba(230,66,66,0.2);
      border-radius: 0px 2px 0px 2px;
      border-bottom: 1px solid #E64242;
      border-left: 1px solid #E64242;
    }

    .minIcon {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 26px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #52C41A;
      line-height: 20px;
      background: rgba(82,196,26,0.2);
      border-radius: 0px 2px 0px 2px;
      border-bottom: 1px solid #52C41A;
      border-left: 1px solid #52C41A;
    }
  }
  .noDataSty {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.1);
    border-radius: 2px;
    color: rgba(255,255,255,0.65);
  }
}