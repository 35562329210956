.cardListStyle {
  margin-top: 20px;

  .cardListColStyle {
    border-top: 1px solid transparent;
    margin-bottom: 12px;
  }
  .cardListColStyle:hover {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    border-top: 1px solid #4a90e2;
    // cursor: pointer;
  }
}
