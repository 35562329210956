@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url('../../../../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
}

.header_area_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-image: url(../../imgs/mainTittleLine.gif);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .date_box {
    flex: 1;
    width: 0px;
    .location {
      display: flex;
      align-items: center;
      .imgSty {
        width: 16px;
        height: 16px;
      }
      .addressSty {
        width: 260px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .tag_img {
      width: 16px;
      height: 16px;
    }
  }
  .set_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    width: 0px;
    .refreshTimeSty {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.65);
      line-height: 20px;
      margin-right: 16px;
    }
  }
  .title_box {
    width: 720px;
    height: 56px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn {
    width: 76px;
    height: 32px;
    background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    
    .bottomLine {
      position: absolute;
      bottom: 0;
      width: 76px;
      height: 1px;
      background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
    }
  }
}