.pageNotAuthorized {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 15px;
  // background-color: #f5f5f5;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--component-background);
  }

  .logo {
    background: url('./notAuthorized.png') no-repeat center center;
    background-size: contain;
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
    margin-top: -40px;
  }

  .text {
    color: #999;
    font-size: 16px;
  }
}
