.month_elec {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 5px 0 10px;
}

.circle_compare {
  font-size: 16px;
  font-weight: bold;
  color: #f5222d;
  padding: 0 5px 0 10px;
}

.tree_outer {
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);

  .tree_box {
    padding: 10px;
  }
}

.container_box {
  // padding-top: 10px;
  height: calc(100% - 32px);
  :global {
    .ant-spin-nested-loading {
      height: calc(100% - 30px);
    }
    .ant-spin-container {
      height: 100%;
    }
  }
}

:global {
  .tree_box {
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: #4a90e2;
    }
  }
}
