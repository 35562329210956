.TopSummaryStyle {
  // width: calc(100% - 600px);
  opacity: 0.9;
  height: 96px;
  padding: 16px;
  margin-top: 10px;
  background-color: #101e2f;
  .TopItemInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    .TopLabel {
      font-size: 12px;
      line-height: 17px;
      padding-bottom: 4px;
    }
    .TopValue {
      font-size: 22px;
      font-weight: 700;
      color: #00adff;
    }
    .upperLowPercent {
      margin-left: 6px;
    }
  }
}
