.block {
  position: absolute;
  top: 76px;
  left: 50%;
  transform: translateX(-50%);
}

.item {
  padding-left: 10px;
  height: 24px;
  position: relative;
  font-size: 12px;
  .pipe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;
  }
}

.txt {
  top: 20px;
  padding-left: 50px;
  line-height: 24px;
}
