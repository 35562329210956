.picker_box {
  position: absolute;
  top: -24px;
  right: 0px;
}

.picker_pos {
  position: relative;
  height: 90%;
}
