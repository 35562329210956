.inner_bottom_left {
  width: 0;
  flex: 1;
  height: 100%;
  display: flex;
}
.inner_bottom_right {
  width: 0;
  flex: 1;
  height: 100%;
  display: flex;
}