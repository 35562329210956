.bottomStyles {
  width: 100%;
}
.tab_title_box {
  display: flex;
  align-items: center;
  gap: 3;
  .tab_title_txt {
  }
  .tab_title_img {
    width: 16px;
    margin-right: 5px;
    margin-bottom: 1px;
    img {
      display: block;
      width: 100%;
    }
  }
}

.page_box {
  height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  .tabs_box {
    position: sticky;
  }
  .tabs_box,
  .line_box,
  .card_box,
  .pagination {
    padding: 0 20px;
  }
  .scroll_area {
    flex: 1;
    overflow: hidden;
    position: relative;
    .empty_box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .scroll_box {
      height: 100%;
      overflow-y: auto;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 20px;
  }
}
.card_box {
  .card_title {
    color: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: space-between;
    padding: 35px 0 12px;
    .left {
      display: flex;
      align-items: center;
      gap: 5px;
      .img_box {
        width: 16px;
        img {
          display: block;
          width: 100%;
        }
      }
    }
    .right {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  &:first-child {
    .card_title {
      padding: 5px 0 12px;
    }
  }

  .table {
    :global {
      .ant-empty-image {
        height: 40px !important;
      }
      .ant-table-tbody > tr {
        .self_color {
          background: #29292c;
        }
      }
    }

    .table_item {
      border: 1px solid rgba(255, 255, 255, 0.2);
      min-height: 100%;
      :global {
        .ant-table-tbody > tr > td {
          padding: 13px 16px;
        }
      }
      .card_title {
        color: rgba(255, 255, 255, 0.85);
        padding: 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
}
