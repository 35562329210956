.table_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  justify-content: flex-end;
  .red {
    color: #d62500;
    padding: 0 5px;
    font-weight: 500;
  }
}
