
%animation-share {
  content: '';
  left: 50%;
  top: 50%;
  position: absolute;
  width: 1em;
  height: 1em;
  margin: -50% -50%;
  border-radius: 50%;
  border: 1px solid #ec2020;
}
.node_box_outer {
  position: relative;
  width: 160px;
  height: 40px;
  .collapse_box {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    right: -28px;
    top: 50%;
    transform: translateY(-50%);
    background: #3570BD;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4A90E2;
    cursor: pointer;
    .collapse_open {
      width: 11px;
      height: 1px;
      background: #FFFFFF;
    }
    &:hover {
      border-color: #fff;
    }
  }
  .node_box {
  
    height: 100%;
    background: #2D2D2D;
    border-radius: 2px;
    border: 1px solid rgba(255,255,255,0.2);
    display: flex;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    
   
    .icon {
      width: 20px;
      margin-right: 8px;
      img {
        display: block;
        width: 100%;
      }
    }
    .label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .alarm_box {

      color: #E64242;
      font-size: 12px;
      position: relative;
        &::before {
          @extend %animation-share;
          animation: wave 2s ease-in-out 0.4s infinite normal none;
        }
    
        &::after {
          @extend %animation-share;
          animation: wave 2s ease-in-out 1.6s infinite normal none;
        }
    

    }
    &:hover {
      border-color: #fff;
    }
  }
  .selected {
    background-color: #1E3557;
    border-color: #fff;
  }
  
}

.precinct_box {
  width: 800px;
  min-height: 80px;
  background: #282F39;
  border-radius: 2px;
  border: 1px solid #334F6F;
  display: flex;
  flex-direction: column;
  .header_box {

    height: 32px;
    background: #2D3E53;
    padding: 0 8px;
    border-bottom: 1px solid #334F6F;
    display: flex;
    align-items: center;
    .main_title {
      font-weight: 500;
      color: rgba(255,255,255,0.85);
    }
    .sub_title {
      font-size: 12px;
      color: rgba(255,255,255,0.65);
    }
  }
  .scroll_box {
    flex: 1;
    
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  .content_box {
    padding: 8px;
    padding-right: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    .content_box_item {
      width: 96px;
      height: 32px;
      color: rgba(255,255,255,0.85);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      background: url('./icons/squareGreen@2x.png') 0 0 repeat;
      background-size: 8px 4px;
      // background-image: repeating-linear-gradient(
      // 90deg, 
      // transparent, 
      // transparent 8px, 
      // #282F39 9px, 
      // );
      position: relative;
      overflow: hidden;
      .content_box_txt {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        z-index: 3;
      }
      // &::after {
      //   content: '';
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   background-image: repeating-linear-gradient(
      //   180deg, 
      //   transparent, 
      //   transparent 3px, 
      //   #282F39 4px);
      // }
      &.alarm {
        background: url('./icons/squareRed@2x.png')  repeat;
        background-size: 8px 4px;
        // background-color: #5E3E49;
        color: #FFCB47;
        opacity: 1;
      }
    }
    .high_light_close {
      opacity: 0.4;

      &.alarm {
        opacity: 0.4;
        
      }
    }
    
    
  
  }
}
.virtual_root_box {
  width: 4px;
  height: 40px;
  // background-color: pink;
}
.virtual_grid_box {
  width: 160px;
  height: 40px;
  // background-color: pink;
}

.tooltip_box {

  .tooltip_title {
    font-size: 12px;
    color: rgba(255,255,255,0.65);
    padding-bottom: 8px
  }
  .tooltip_content {
    display: flex;
    color: rgba(255,255,255,0.85);
    margin-bottom: 8px;
    .tooltip_content_left {
  
    }
    .tooltip_content_right {
      flex: 1;
      text-align: right;
    }
  }
}
@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(0.2);
    opacity: 0.2;
  }
  20% {
    transform: scale(0.4);
    opacity: 0.4;
  }
  30% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  40% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  60% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  80% {
    transform: scale(1.6);
    opacity: 0.4;
  }
  90% {
    transform: scale(1.6);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
