.contentWrapper {
  background-color: var(--mx-module-bg-color);
  padding: 10px 10px 20px 30px;
  .divider {
    color: var(--mx-text-base-color);
    &::before {
      width: 0 !important;
    }
  }
  .content {
    padding-left: 40px;
  }
}
