.thresholdTitle {
  display: flex;
  align-items: center;

  .thresholdIcon {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}

.mb0 {
  margin-bottom: 0;
}
