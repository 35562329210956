.wrapper {
  padding: 0;
  min-height: calc(100vh - 128px);

  :global {
    .ant-tabs-content {
      padding: 4px 20px 20px;
    }
  }
}
