.wrapper {
  padding: 0;
  background: none;
}

.layout {
  min-height: calc(100vh - 128px);
  background-color: transparent;
}

.empty_box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  background: var(--component-background);
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.contentInner {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: calc(100vh - 130px);
  .time_box {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .content_box {
    flex: 1;
    height: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    .top_card_box {
      height: 120px;
      margin-bottom: 16px;
    }
  }
  .tag_label {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255,255,255,0.65);
  }
  .tag_value {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255,255,255,0.85);
  }
}
