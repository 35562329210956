.echart_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 345px);
  min-height: 400px;
}
.card_area {
  margin-top: 15px;
}
.card_box {
  width: 261px;
  height: 78px;
  background: rgba(255,255,255,0.1);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 15px;
  box-sizing: border-box;
  .line_title {
    color: rgba(255,255,255,0.65);
  }
  .line_value {
    font-weight: 500;
    color: rgba(255,255,255,0.85);
    font-size: 20px;
  } 
}