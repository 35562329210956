@import '@maxtropy/components/es/lib/theme/dark.scss';
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'PingFang SC', 'Microsoft YaHei', '黑体', '宋体', sans-serif;
  min-width: 1366px;
}

// remove this in v5
.ant-table.ant-table-middle {
  font-size: 12px;
}

.ant-table-cell .ant-btn {
  font-size: 12px;
}
