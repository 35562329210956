.steps_box {
  width: 90%;
  margin: 7px auto 15px;
}
.page_box {
  :global {
    .page__content {
      min-height: calc(100vh - 128px);
    }
  }
}
