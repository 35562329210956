@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

@keyframes pathRotate {
  0%,
  100% {
    clip-path: inset(0 0 0 98%);
  }

  25% {
    clip-path: inset(98% 0 0 0);
  }

  50% {
    clip-path: inset(0 98% 0 0);
  }

  75% {
    clip-path: inset(0 0 98% 0);
  }
}

@keyframes beat {
  0% {
    bottom: 3%;
  }

  100% {
    bottom: 7%;
  }
}

.wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: -167px;
  margin-top: -180px;

  .top {
    .frame {
      display: inline-block;
      padding: 4px 4px 4px 4px;
      background-repeat: no-repeat;

      cursor: pointer;
      z-index: 33;

      .frame_box {
        width: 315px;
        max-height: 0;
        min-height: 106px;

        transition: all 3s ease;
        padding: 18px;
        position: relative;
        z-index: 3;
        overflow: hidden;
        background-image: url('../../../../assets/images/energyGateway/panel01@2x.png');
        background-size: 100% 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        
        .block_shadow {
          position: absolute;
          left: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 100%;
          transition: all 3s ease;
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.23);
        }

        .boardTop {
          position: absolute;
          width: 315px;
          height: 9px;
          top: -1px;
          left: 0;
          background-image: url('../../../../assets/images/energyGateway/boardTop@2x.png');
          background-size: 315px 9px;
          background-repeat: no-repeat;
        }

        .entrance {
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          width: 108px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 25px;
        }

        .buttonList {
          
          display: flex;
        
          position: relative;
          z-index: 100;
          flex-wrap: wrap;
          padding: 0;
          // width: 268px;
          margin-bottom: 0px;
          padding-top: 10px;
          // height: 0px;
          max-height: 0;
          overflow: hidden;
          transition: all 0.5s ease;

          

          li {
            list-style: none;
            padding: 0;
            width: 130px;
            height: 28px;
            background-image: url('../../../../assets/images/energyGateway/button-bg@2x.png');
            background-size: 130px 28px;
            background-repeat: no-repeat;
            cursor: pointer;
            // 文字居中
            display: flex;
            align-items: center;
            padding-left: 6px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            overflow: hidden;
            transition: all 0.3s ease-in-out;

            &:hover {
              background-image: url('../../../../assets/images/energyGateway/button-hover@2x.png');
            }
          }

          li:nth-of-type(odd) {
            margin-right: 18px;
          }

          li:nth-of-type(1),
          li:nth-of-type(2) {
            margin-bottom: 10px;
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          position: relative;
          z-index: 99;
          gap: 10px;
          .label_unit {
            p {
              margin: 0;
            }
          }
          .stationName {
            // position: absolute;
            // left: 19px;
            // top: 52px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
          }
  
          .stationNameWrap {
            // position: absolute;
            // left: 19px;
            // top: 65px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
  
          .deviceName {
            // position: absolute;
            // left: 83px;
            // top: 52px;
            
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
          }
  
          .deviceNameWrap {
            // position: absolute;
            // left: 109px;
            // top: 65px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
          }
  
          .capacityUnit {
            // position: absolute;
            // left: 164px;
            // top: 52px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
          }
  
          .capacityUnitWrap {
            // position: absolute;
            // left: 190px;
            // top: 65px;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 17px;
          }
          .stationNum {
            // position: absolute;
            // left: 19px;
            // top: 22px;
            height: 30px;
            width: 80px;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 显示省略号 */
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 30px;
          }
  
          .deviceNum {
            // position: absolute;
            // left: 83px;
            // top: 22px;
            height: 30px;
            width: 75px;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 显示省略号 */
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 30px;
          }
  
          .totalCapacity {
            // position: absolute;
            // left: 164px;
            // top: 22px;
            width: 120px;
            height: 30px;
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
        }

        

        

        .arrow {
          position: absolute;
          width: 24px;
          height: 24px;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
          background-image: url('../../../../assets/images/energyGateway/arrow@2x.png');
          background-size: 24px 24px;
          background-repeat: no-repeat;
          animation-name: beat;
          animation-duration: 0.5s;
          /*设置秒数*/
          animation-timing-function: linear;
          /*速度曲线*/
          animation-iteration-count: infinite;
          /*播放次数*/
          animation-direction: alternate;
          /*逆向播放*/
          animation-play-state: running;
          /*正在运行*/
        }

        .triangle {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -10px;
          width: 20px;
          height: 11px;
          background-image: url('../../../../assets/images/energyGateway/triangle01@2x.png');
          background-size: 20px 11px;
          background-repeat: no-repeat;
        }

        .pointTL {
          position: absolute;
          top: 6px;
          left: 6px;
          width: 4px;
          height: 4px;
          background-color: #fff;
          opacity: 0.6;
          z-index: 44;
        }

        .pointTR {
          position: absolute;
          top: 6px;
          right: 6px;
          width: 4px;
          height: 4px;
          background-color: #fff;
          opacity: 0.6;
          z-index: 44;
        }

        .pointBL {
          position: absolute;
          bottom: 6px;
          left: 6px;
          width: 4px;
          height: 4px;
          background-color: #fff;
          opacity: 0.6;
          z-index: 44;
        }

        .pointBR {
          position: absolute;
          bottom: 6px;
          right: 6px;
          width: 4px;
          height: 4px;
          background-color: #fff;
          opacity: 0.6;
          z-index: 44;
        }
      }
    }
  }

  .bottom {
    width: 50px;
    height: 93px;
    background-size: 50px auto;
    background-repeat: no-repeat;
  }
}

.wrapper_box {
  bottom: 73% !important;

  .bottom {
    height: 30px;
    background-position: center bottom;
  }
}

.hover_box {
  &:hover {
    max-height: 600px!important
    // height: 178px !important;
    // background-size: 315px 178px !important;
  }

  &:hover .arrow {
    display: none !important;
  }

  // &:hover .block_shadow {
  //   height: 178px !important;
  // }

  &:hover .buttonList {

    max-height: 200px!important
    // height: auto!important;
    // height: 66px !important;
  }
}
