.top {
  padding: 10px;
  .today_value {
    display: inline-block;
    width: 128px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
    .noInfo {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff80;
      line-height: 12px;
    }
  }
  .elec {
    display: inline-block;
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    line-height: 17px;
    font-size: 12px;
    height: 17px;
  }
  .line_through {
    position: relative;
    margin: 2px 0;
    border-bottom: 1px solid #ffffff25;
    .line_head {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 40px;
      border-bottom: 1px solid #00adff;
    }
  }
}
