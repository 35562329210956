.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  .left_box {
    flex: 1;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .label {
        color: rgba(255, 255, 255, 0.65);
      }
      .value {
        font-weight: 500;
        font-size: 20px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .label {
        color: rgba(255, 255, 255, 0.65);
      }
      .value {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }
  .right_box {
    flex: 1;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .label {
        color: rgba(255, 255, 255, 0.65);
      }
      .value {
        font-weight: 500;
        font-size: 20px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .label {
        color: rgba(255, 255, 255, 0.65);
      }
      .value {
        font-weight: 500;
        font-size: 20px;
      }
    }
  }
}
