.form {
  :global {
    .ant-form-item-label > label {
      white-space: normal;
      height: 38px;
    }
  }
}

.icon {
  color: #9e730e;
  margin-right: 5px;
}

.table_text {
  display: flex;
  justify-content: flex-end;
}

.input-suffix {
  margin-bottom: 24px;
  margin-left: 80px;
}

.line_red {
  color: red;
}

.empty_h {
  height: 20px;
}

.base_content {
  color: var(--mx-text-base-color);
}

.tips_text {
  font-size: 12px;
  color: var(--mx-text-desc-color);

  .txt {
    padding: 0 5px;
  }
}

.flex {
  display: flex;
}

.xing {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ac222a;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.selectSearchString {
  color: red;
  font-weight: bold;
  font-style: normal;
}

.wrapperPadding {
  padding: 20px;
  min-height: calc(100vh - 128px);
}
