.container {
  display: flex;
  flex-direction: column;
  .headerWrapper {
    display: flex;
    align-items: center;
    height: 56px;
    .title {
      color: var(--text-color);
      font-weight: 500;
      font-size: 16px;
    }
    .tooltip {
      color: var(--mx-text-desc-color);
      line-height: 22px;
      font-size: 14px;
      & > span {
        margin-left: 4px;
      }
    }
    .titleBorder {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  .empty_box {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}