.tableWrap {
  .item {
    width: 100%;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    font-size: 12px;

    &:not(:first-of-type) {
      margin-top: 6px;
    }

    > * {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.maxH130 {
  max-height: 130px;
  overflow-y: auto;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
