.filterBox {
  margin-bottom: 40px;
  .divider {
    position: absolute;
    left: 0px;
    width: 100vw;
    background: #212d3f;
    height: 10px;
    z-index: 1;
    top: 248px;
  }
}
.header {
  padding: 10px 0;
}
.sign {
  color: var(--mx-primary-color);
}
.list_box {
  overflow-y: auto;
  height: 500px;
  :global {
    .ant-tag {
      margin-bottom: 5px;
    }
  }
}
.list_line {
  display: flex;
  margin-bottom: 10px;
  .left {
    width: 100px;
    color: var(--mx-primary-color);
    margin-right: 10px;
  }
  .right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }
}

.tabPopup {
  flex: none;
}
.tab_box {
  :global {
    .ant-tabs > .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        flex: none;
      }
    }
  }
  .tab_name {
    color: var(--mx-primary-color);
    margin-left: 30px;
  }
}

.wrapperTabsPadding {
  padding: 20px;
  min-height: calc(100vh - 128px);
}
