.CollapseStyle {
  opacity: 0.9;
  width: 320px;
  padding: 10px;
  border-radius: 4px;
  :global {
    .ant-collapse-header {
      background-color: #202020;
    }
    .ant-collapse-expand-icon {
      position: absolute;
      right: 1px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
    .ant-collapse-content {
      border-top: 0;
    }
    .ant-collapse {
      border: 0;
    }
    .ant-collapse > .ant-collapse-item {
      border: 0;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 10px 16px;
    }
  }
}
