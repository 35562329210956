.treeArea {
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;

  .searchArea {
    padding: 0 10px;
    margin: 12px 0;

    :global {
      .ant-input {
        background-color: transparent;
      }
    }
  }

  .treeBox {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
}

.iconSty {
  width: 16px;
  height: 16px;
}

.filter_text {
  color: red;
}
