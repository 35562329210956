.drawerTitle {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
}

.drawerStyle {
  margin-top: 50px;
  height: calc(100% - 50px) !important;
  :global {
    .ant-drawer-header {
      border-top: 2px solid #1aa3df;
      padding: 11px 24px 10px 24px;
    }
  }
}

.title {
  font-weight: 500;
  color: rgba(255,255,255,0.85);
  line-height: 22px;
}
.value{
  font-weight: 500;
  font-size: 18px;
  color: #00ADFF;
  line-height: 26px
}

.top_box {
  margin-bottom: 12px;
}

.center_box {
  padding: 16px 0;
}

.bottom_box {
  margin-top: 24px;
  .inverter_total{
    height: 40px;
    background: rgba(0,118,255,0.05);
    border-radius: 2px;
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(10px);
    padding: 8px 16px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item_box {
    background: rgba(0,118,255,0.05);
    border-radius: 2px;
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    margin-bottom: 8px;
    .item_top_box {
      height: 40px;
      border-bottom: 1px solid rgba(255,255,255,0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
    }
    .item_bottom_box {
      padding-top: 14px;
      flex: 1;
      display: flex;
      align-items: center;
      .item_power {
        width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 8px;
      }
    }
  }
}