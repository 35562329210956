.wrap {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.bottomWrap {
  display: flex;
  gap: 16px;
  margin-top: auto;

  .bottomCard {
    flex: 1;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    background: rgba(74, 144, 226, 0.05);
    border-radius: 2px;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    .value {
      margin-left: auto;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.warningColor {
  color: var(--warning-color);
}
