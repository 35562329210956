.personalFormContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  .borderStyles {
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #0583ff 90%, rgba(40, 150, 255, 0) 100%);
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    .formTitle {
      position: relative;
      color: var(--text-color);
      font-weight: bold;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .titleBorder {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    // padding: 8px 16px 10px;
    padding: 0px 16px;
  }
}
