.desc {
  margin-top: 8px;
  white-space: nowrap;
}

.infoIcon {
  margin-right: 5px;
  color: var(--warning-color);
}

.mb6 {
  margin-bottom: 6px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.noStyleItem {
  margin-bottom: 0 !important;

  :global {
    .ant-form-item-control {
      max-width: 100%;
    }
  }
}
