.searchArea {
  padding: 0 10px;
  margin-bottom: 12px;
  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}

.treeArea {
  padding-bottom: 6px;

  :global {
    .ant-tree-title {
      overflow: unset;
    }

    .ant-tree-node-content-wrapper {
      overflow: unset;
    }
  }
}

.filterTxt {
  color: red;
}
