.detailDrawer {
  :global {
    .ant-drawer-header::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);
    }
  }

  :global {
    .ant-drawer-close {
      position: absolute;
      right: 27px;
      margin-right: 0;
    }
  }
}

.drawerTitle {
  display: flex;
  align-items: center;

  .titleName {
    display: inline-block;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleTxt {
    font-weight: 500;
  }
}

.card {
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.12);

  .cardRow {
    display: flex;
    padding: 9px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  .label {
    width: 120px;
    min-width: 120px;
    color: rgba(255, 255, 255, 0.65);
  }

  .value {
    flex: 1;
    overflow: hidden;
  }
}

.bindDevice:not(:last-child) {
  margin-bottom: 8px;
}

.colorRed {
  color: rgba(230, 66, 66, 1);
}

.tag {
  margin-left: 7px;
}
