.wrapper {
  padding: 20px;
  .table_wrapper {
    padding: 0px 32px;
  }
}

.afterValue {
  color: #faad14;
}

.modalWrapper {
  margin: 24px;
  padding: 0px;
}
