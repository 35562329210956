.top {
  width: 100%;
  padding: 4px 11px;
  display: flex;
  align-items: center;
  gap: 45px;
  justify-content: space-between;
  border-bottom: 1px dashed  rgba(255,255,255,0.2);
  .top_each {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label_value {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-size: 12px;
        color: rgba(255,255,255,0.65);
        line-height: 20px;
      }
      .value {
        font-weight: 500;
        color: rgba(255,255,255,0.85);
        line-height: 22px;
        width: 40%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;
      }
    }
    .process {
      width: 100%;
      flex: 1;
    }
  }
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column; 
  .authCharge_box {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 11px 0px;
    .label {
      font-size: 12px;
      color: rgba(255,255,255,0.65);
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 2px;
      .icon {
        width: 16px;
        height: 16px;
      }
    }
    .value {
      font-weight: 500;
      color: #52E7FF;
      line-height: 22px;
    }
  }
  .bottom_percent_box {
    width: 100%;
    padding: 0px 11px;
    display: flex;
    align-items: center;
    gap: 45px;
    justify-content: space-between;
    margin-top: -5px;
    .top_each {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .label_value {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          font-size: 12px;
          color: rgba(255,255,255,0.65);
          line-height: 20px;
          .icon {
            width: 16px;
            height: 16px;
          }
        }
        .value {
          font-weight: 500;
          color: rgba(255,255,255,0.85);
          line-height: 22px;
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
      .process {
        width: 100%;
        flex: 1;
      }
    }
  }
}