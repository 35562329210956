.pagination {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: flex-end;
  align-items: center;

  .total {
    // margin-right: 20px;
    color: var(--text-color);
  }
}
