.dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
}

.info_box {
  margin: 20px 0px;
  .icon_sty {
    font-size: 14px;
    margin-right: 6px;
    color: var(--mx-warning-color);
  }
}
