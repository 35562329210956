.form {
  :global {
    .ant-form-item-label > label {
      white-space: normal;
      height: 38px;
    }
  }
}

.icon {
  color: #9e730e;
  margin-right: 5px;
}

.table_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  .red {
    color: #d62500;
    padding: 0 5px;
    font-weight: 500;
  }
}

.input-suffix {
  margin-bottom: 24px;
  margin-left: 80px;
}
.line_red {
  color: red;
}

.empty_h {
  height: 20px;
}
.base_content {
  color: var(--mx-text-base-color);
}
.tips_text {
  font-size: 12px;
  color: var(--mx-text-desc-color);
  .txt {
    padding: 0 5px;
  }
}
.flex {
  display: flex;
}
.select_num {
  font-style: normal;
  color: red;
  padding: 0 3px;
  font-size: 16px;
}

.wrapperPadding {
  padding: 20px;
  min-height: calc(100vh - 128px);
}

.wrapper {
  min-height: calc(100vh - 128px);
}
