.wrapper {
  padding: 20px;
  height: calc(100vh - 128px);
}
.outer_box {
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  .nav_box {
    .top_box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .time_box {
        font-size: 12px;
        color: rgba(255,255,255,0.65);
      }
      
    }
  }
  
  .draw_box {
    position: relative;
    flex: 1;
    overflow: hidden;
    .leftTop_info_box{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 400px;
      height: 160px;
      background: #2D2D2D;
      box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.2), 0px 6px 16px 0px rgba(0,0,0,0.32), 0px 3px 6px -4px rgba(0,0,0,0.48);
      border-radius: 2px;
      border: 1px solid rgba(255,255,255,0.2);
    }
    .graph_box {
      height: 100%;
      border-radius: 2px;
      border: 1px solid rgba(255,255,255,0.2);
      body {
        min-width: 40px;
      }
    }
  }
  
}

