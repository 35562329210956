.page_box {
  :global {
    .page__content {
      min-height: calc(100vh - 128px);
    }
  }
}

.subDividerContent {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);

    .titleLine {
      flex: 1;
      margin-left: 9px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .content {
  }
}

.healthFormItemTip {
  display: flex;
  height: 32px;
  align-items: center;
}
