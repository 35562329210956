.btn {
  position: relative;
  // display: inline-block;
  width: 100%;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-shadow: 0px 7px 13px 0px rgba(44, 196, 249, 0.37);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  background: var(--gradient-background);
  padding: 2px 12px;
  color: #fff;
  &:hover,
  &:focus {
    background: var(--gradient-background);
  }
  &.red {
    color: red;
  }
  &.disable {
    color: #ccc;
    box-shadow: none;
    text-shadow: none;
    cursor: not-allowed;
  }
  .line {
    position: absolute;
    opacity: 0.5;
    border-radius: 1px;
    width: 2px;
    top: 0;
    height: 100%;
    background: white;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  .dot {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 2px;
    background: white;
    opacity: 0.5;
    &--top-left {
      top: 2px;
      left: 5px;
    }
    &--top-right {
      top: 2px;
      right: 5px;
    }
    &--bottom-left {
      bottom: 2px;
      left: 5px;
    }
    &--bottom-right {
      bottom: 2px;
      right: 5px;
    }
  }
}
.setDoing {
  text-align: center;
  font-size: 16px;
  padding: 20px 0;
  color: #fff;
}
