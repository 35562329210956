.cardContainer {
  overflow-x: auto;
  white-space: nowrap;
  // padding-bottom: 10px;
  .cardItem {
    margin-right: 9px;
    width: 220px;
    display: inline-block;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.cardContainerMoreThanTree {
  display: flex;
  padding-bottom: 10px;
  overflow-x: auto;
  .cardItem {
    margin-right: 9px;
    flex: 1;
    min-width: 155px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.layout {
  min-height: calc(100vh - 101px);
  background-color: transparent;
}

.content {
  background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.sider {
  border-radius: 2px;
  transition: all 0.2s;
}

.filter {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
  // flex-wrap: wrap;
}
.commonBtn {
  float: right;
  margin-left: 10px;
}
.tabContent {
  padding: 0 12px;
}
.loss_chart_container {
  position: relative;
  height: calc(100vh - 200px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .loss_chart_box {
    flex: 1;
    overflow: hidden;
  }
}

.loss_chart_empty {
  height: calc(100vh - 158px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chartContainer {
  position: relative;
  height: calc(100vh - 303px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .chart_box {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
.circuitName {
  padding: 12px 10px 10px 0;
}
.moreChartContainer {
  position: relative;
  height: calc(100vh - 210px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .chart_box {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
.tab_box {
  display: flex;
  border-bottom: 1px solid #303030;
  height: 47px;
  margin-bottom: 10px;
}

.spanCursor {
  color: #4a90e2;
  margin: 5px 0 0 10px;
  cursor: pointer;
  display: inline-block;
}
@media only screen and(max-width:1200px) {
  .hidden {
    display: none !important;
  }
}
.position_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
