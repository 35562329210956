.wrapper {
  padding: 20px;
}

.sectionTitle {
  font-size: 16px;
  color: var(--text-color);
  padding-left: 11px;
  line-height: 24px;
  position: relative;
  margin-top: 35px;
  &::before {
    content: '';
    position: absolute;
    background: var(--primary-color);
    width: 4px;
    height: 16px;
    left: 0;
    top: 4px;
  }
}
.imgTitle {
  padding-top: 15px;
}
.imgContent {
  padding: 10px 0;
}
.imgTips {
  width: 75%;
  color: rgba(255, 255, 255, 0.65);
}

.imgDefault {
  display: flex;
  justify-content: flex-start;
}

.imgDefaultBox {
  border: 1px solid rgba(0, 173, 255, 0.5);
  border-radius: 2px;
  margin-top: 30px;
  margin-left: 40px;
  width: 104px;
  height: 104px;
}

.logoDefaultBox {
  border: 1px solid rgba(0, 173, 255, 0.5);
  border-radius: 2px;
  margin-top: 30px;
  margin-left: 40px;
  width: 350px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wiringDiagram {
  border: 1px solid rgba(0, 173, 255, 0.5);
  border-radius: 2px;
  margin-left: 40px;
  width: 104px;
  height: 104px;
}
