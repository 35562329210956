.calendarContent {
  width: 100%;
  border-radius: 2px;
  background: #2d2c2c;
  .calendar {
    margin-top: 20px;
    background: #2d2c2c;
    .head {
      background: #2d2c2c;
      padding: 10px 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      display: flex;
      justify-content: space-between;
      :global {
        .ant-radio-button-wrapper {
          background-color: rgba(74, 144, 226, 0.16);
          border: 1px solid rgba(0, 173, 255, 0.5);
        }
        .ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper:hover {
          background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);
          color: rgba(255, 255, 255, 0.65);
        }
        .ant-radio-button-wrapper:focus-within {
          box-shadow: none;
        }
      }
    }
    .date {
      font-size: 16px;
    }
  }
  .bottomBox {
    display: flex;
    padding: 10px;
    p {
      margin: 0;
      width: 100px;
    }
    .dates {
      max-height: 120px;
      overflow-y: auto;
    }
    .dateRender {
      display: flex;
      margin-bottom: 5px;
      p {
        margin: 0;
        color: #4a90e2;
        min-width: 100px;
      }
      :global {
        .ant-tag {
          margin-bottom: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
.active {
  background: rgba(74, 144, 226, 0.2) !important;
  width: 100%;
  height: 100%;
  .icon {
    color: #14d0e4;
  }
  .date {
    color: #4a90e2;
  }
}
.disabled {
  cursor: no-drop;
}
.box {
  height: 100px;
  text-align: left;
  border: 1px solid #49494a;
  padding: 5px;
  position: relative;
  p {
    margin: 0;
  }
  .tit {
    width: 80%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    line-height: 22px;
    vertical-align: middle;
    white-space: nowrap;
  }
  .btns {
    position: absolute;
    bottom: 0;
    right: 0;
    div,
    a {
      color: #14d0e4;
      background: transparent;
      padding: 0;
      z-index: 999;
    }
    :global {
      .anticon {
        background: rgba(20, 208, 228, 0.09);
        padding: 3px;
      }
    }
  }
}
:global {
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: 100px;
    padding: 10px 25px 5px 0;
    border: 1px solid #49494a;
    font-size: 16px;
    vertical-align: top;
    font-weight: 500;
  }
  .ant-picker-calendar-full .ant-picker-panel {
    width: calc(100% - 20px);
    margin: 10px;
  }
  .ant-picker-cell-today {
    div:first-child {
      border-color: #4a90e2;
    }
  }
}

.err {
  text-align: center;
  padding: 5% 0;
}
.errIcon {
  color: var(--mx-warning-color);
  margin-right: 5px;
}
