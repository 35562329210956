.RightInfoTitle {
  height: 32px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border-left: 2px solid #4a90e2;
}

.ItemInfo {
  padding: 14px 12px;
  display: flex;
  align-items: center;
  .valueInfo {
    padding-left: 16px;
    .valueStyle {
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      word-break: break-word;
    }
  }
}
