.wrapper {
  padding: 20px;
  min-height: calc(100vh - 128px);
}

.splitLine {
  width: 100%;
  margin: 24px 0;
  border: 1px dashed rgba(255, 255, 255, 0.2);
}
