.content {
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    height: 200px;
    width: 200px;
    display: inline-block;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    color: var(--mx-text-base-color);
    line-height: 25px;
  }
}
