.RightInfoTitle {
  height: 32px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border-left: 2px solid #4a90e2;
}

.ItemInfo {
  padding: 20px 12px 10px 12px;
  font-size: 12px;
  display: flex;
  // justify-content:center;
  align-items: center;
  :global {
    .ant-progress-steps-item {
      width: 4px !important;
      height: 16px !important;
      margin-right: 1px !important;
    }
    .ant-progress-text {
      margin-left: 6px;
    }
  }
}

.currentMonthMaxMinPointSty {
  padding: 10px 12px;
  :global {
    .ant-progress-line {
      width: 95%;
    }
  }
}

.percentTitleAndName {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.DividerStyle {
  padding: 0px 8px;
  :global {
    .ant-divider-horizontal {
      margin: 0px;
    }
  }
}
