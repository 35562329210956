.wrapper {
  padding: 20px;
}

.list {
  .content {
    display: flex;
    padding: 20px 10px;
    border-bottom: 1px solid;
    border-image: linear-gradient(-270deg, rgba(0, 173, 255, 0), #00adff, rgba(0, 173, 255, 0.01)) 30 30;
    margin-bottom: 10px;
    cursor: pointer;
    background: rgba(74, 144, 226, 0.09);
    .leftContent {
      min-width: 200px;
      margin-right: 15px;
      img {
        width: 100%;
        margin-top: 5px;
      }
      .name {
        font-weight: 500;
        margin: 0;
        color: #0db6d9;
        font-size: 18px;
        overflow: hidden;
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .word_wrap {
      word-wrap: break-word;
    }
    .actionContent {
      text-align: right;
      margin-top: 20px;
      .action {
        margin-left: 30px;
        position: relative;
        display: inline-block;
        text-align: left;
        .btn {
          padding-left: 0;
          padding-right: 0;
          margin-right: 10px;
        }
        .gray {
          color: rgba(255, 255, 255, 0.8);
        }
        &.red::before {
          background-color: red;
        }
        &.red {
          .btn {
            color: red;
          }
        }
        &.disable:before {
          background: rgba(255, 255, 255, 0.5);
          cursor: not-allowed;
        }
        &.disable {
          .btn {
            color: rgba(255, 255, 255, 0.5);
            cursor: not-allowed;
          }
        }
      }
    }
    :global {
      .ant-descriptions .ant-descriptions-item-label {
        color: var(--mx-text-secondary-color);
      }
      .ant-descriptions-row > th {
        padding-bottom: 8px;
      }
      .ant-descriptions-row > td {
        padding-bottom: 0;
      }
    }
  }
}

.icon {
  color: #0db6d9;
  background: rgba(13, 182, 217, 0.16);
  width: 22px;
  height: 22px;
  line-height: 28px;
  border-radius: 20px;
  text-align: center;
  padding-left: 4px;
}

.img_bottom_line {
  padding: 5px 0;
  color: var(--mx-text-secondary-color);
}
.img_bottom_num {
  color: var(--mx-link-color);
  padding-left: 5px;
}
