.ColSty {
  background: #232324;
  padding: 10px;
  min-height: 258px;
  // min-height: 264px;
  // margin-right: 10px;
  :global {
    .ant-spin-nested-loading {
      width: 100%;
    }

    .ant-progress-text {
      margin-left: 4px;
    }
  }
}
.cardDivSty {
  min-width: 160px;
  height: 70px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 8px;
  position: relative;
  .cardDivStyTitle {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    line-height: 20px;
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .cardDivStyImg {
    position: absolute;
    bottom: 8px;
    right: 8px;
    .ImgSty {
      width: 20px;
      height: 20px;
    }
  }
}
