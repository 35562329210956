.container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.block_box {
  width: 124px;
  height: 63px;
  margin: 5px;
  background: rgba(0, 173, 255, 0.1);
  border: 1px solid #4a90e2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .img {
    width: 50px;
    img {
      display: block;
      width: 100%;
    }
  }
  .content {
    text-align: center;
  }
  .label {
    font-size: 14px;
    color: #ffffff;
  }
  .value {
    font-weight: 500;
    font-size: 16px;
  }
}
.title_box {
  display: flex;
  .block_line {
    min-width: 124px;
    height: 63px;
    margin: 5px 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .img {
      width: 54px;
      img {
        display: block;
        width: 100%;
      }
    }
    .content {
      text-align: center;
    }
    .label {
      font-size: 14px;
      color: #ffffff;
    }
    .value {
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.superLow {
  border: 1px solid #50fb00;
  .value {
    color: #50fb00;
  }
}

.low {
  border: 1px solid #14e4cc;
  .value {
    color: #14e4cc;
  }
}
.normal {
  border: 1px solid #4a90e2;
  .value {
    color: #4a90e2;
  }
}
.high {
  border: 1px solid #f9b600;
  .value {
    color: #f9b600;
  }
}
.superHigh {
  border: 1px solid #ec242a;
  .value {
    color: #ec242a;
  }
}
.noLink {
  border: 1px solid #d9d9d9;
  .value {
    color: #d9d9d9;
  }
}
