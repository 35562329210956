.customStatic {
  color: var(--mx-text-secondary-color);
  display: flex;
  flex-direction: column;
  gap: 6px;
  .indexInfo {
    display: flex;
    justify-content: space-between;
    .indexName {
      font-size: 14px;
      margin-right: 8px;
      color: var(--mx-text-secondary-color);
      line-height: 28px;
    }
    .indexVal {
      font-size: 20px;
      color: var(--mx-text-base-color);
      line-height: 28px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
