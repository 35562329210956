:global {
  .ant-image-preview-root .ant-image-preview-mask{
    background-color: rgba(0, 0, 0, 0.75);;
  }
}

.wrapper{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 60px);
  height: 100%;
  margin: 0px 30px;
  .spaceSty {
    height: 100%;
    .link_sty {
      height: 100%;
      color: rgba(255, 255, 255, 0.85);
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    :global {
      .ant-space-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.border {
  // width: 420px;
  // height: 300px;
  background: rgba(18, 18, 18, 0.7);
  border: 1px solid;
  border-image: linear-gradient(360deg, rgba(0, 173, 255, 0), rgba(0, 173, 255, 1), rgba(0, 173, 255, 0.01)) 1 1;
  .img_border {
    display: flex !important;
    justify-content: center;
  }
  :global {
    .ant-carousel .slick-dots {
      bottom: 0;
    }
    .ant-carousel .slick-dots li.slick-active,
    .ant-carousel .slick-dots li {
      width: 0;
    }
  
    .ant-carousel .slick-dots li.slick-active button,
    .ant-carousel .slick-dots li button {
      opacity: 1;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
    .ant-carousel .slick-dots li.slick-active button {
      background: #4a90e2;
    }
  
    .ant-carousel .slick-dots li button {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}

