%flex-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%flex-header-footer-share {
  @extend %flex-share;
  padding: 0 12px;
  box-sizing: border-box;
}

%animation-share {
  content: '';
  left: 50%;
  top: 50%;
  position: absolute;
  width: 1em;
  height: 1em;
  margin: -50% -50%;
  border-radius: 50%;
  border: 1px solid #ec2020;
}

.cardWrapper {
  background: linear-gradient(135deg, #303b51 0%, #1a2737 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.12);

  overflow: hidden;

  display: flex;
  flex-direction: column;

  .cardHeader {
    flex: 32px 0 0;
    @extend %flex-header-footer-share;

    font-size: 14px;
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 20px;
  }

  .cardContentWrapper {
    // padding-bottom: 13px;
    padding-top: 6px;
    flex: 1;
    box-sizing: border-box;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.12);

    @extend %flex-share;

    .content {
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      padding: 0 12px;

      display: flex;
      justify-content: center;
      flex-direction: column;

      &:nth-child(n) {
        border-right: 1px solid rgba(255, 255, 255, 0.12);
      }
      &:nth-last-child(1) {
        border-right: none;
      }

      .value {
        font-weight: bold;
        color: #00adff;
        line-height: 17px;
      }

      .desc {
        font-size: 12px;
        font-weight: 400;
        margin-right: 8px;
        color: var(--mx-text-secondary-color);
        line-height: 17px;
      }

      .rightTitle {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 8px;
        text-align: center;
        color: var(--mx-text-secondary-color);
        line-height: 17px;
      }
    }
  }

  .cardFooter {
    flex: 32px 0 0;
    @extend %flex-header-footer-share;
    font-size: 12px;

    .alertContent {
      height: 17px;
      font-weight: 400;
      color: var(--mx-text-base-color);
      line-height: 17px;
    }
  }
  .BtnLinkFooter {
    display: flex;
    justify-content: flex-end;
    padding: 2px 12px;
  }
}

@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(0.2);
    opacity: 0.2;
  }
  20% {
    transform: scale(0.4);
    opacity: 0.4;
  }
  30% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  40% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  60% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  80% {
    transform: scale(1.6);
    opacity: 0.4;
  }
  90% {
    transform: scale(1.6);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.alert {
  color: #ec2020;
  .icon {
    position: relative;
    &::before {
      @extend %animation-share;
      animation: wave 2s ease-in-out 0.4s infinite normal none;
    }

    &::after {
      @extend %animation-share;
      animation: wave 2s ease-in-out 1.6s infinite normal none;
    }
  }
}

.title {
  .sTitle:hover {
    color: #4a90e2 !important;
  }
  :global {
    .ant-btn-link {
      color: #ffffff;
    }
    .ant-btn-link:hover {
      color: #4a90e2;
    }
  }
}
.valueContainer2 {
  margin-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    font-size: 12px;
    .name {
      color: var(--mx-text-secondary-color);
    }
    .desc {
      font-weight: 400;
      color: var(--mx-text-base-color);
      line-height: 17px;
    }
    .info {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 80px);
    }
    .value {
      font-weight: 500;
      color: #ffffff;
      line-height: 17px;
    }
    .line {
      margin-left: 6px;
      margin-right: 4px;
      flex: 1;
      height: 1px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
  .tenantItem {
    margin-bottom: 5px;
  }
}
