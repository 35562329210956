.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 8px;
  // max-width: 220px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.65);

  .cardTitle {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
  }
  .valueContainer {
    margin-top: 20px;
    .value {
      font-size: 20px;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  :global {
    .ant-icon {
    }
  }
}
