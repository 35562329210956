.container {
  display: flex;
  flex-direction: column;
  .headerWrapper {
    display: flex;
    align-items: center;
    height: 56px;
    .title {
      color: var(--text-color);
      font-weight: 500;
      font-size: 16px;
    }
   
    .titleBorder {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  .content_box {
    height: 407px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .chart_box{
      width: 100%;
      height: 100%;
      .spin_box {
        width: 100%;
        height: 100%;
        margin: auto;
      }
      .empty_box {
        height: 340px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

