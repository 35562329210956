.container {
  display: flex;
  flex-direction: column;
  .headerWrapper {
    display: flex;
    align-items: center;
    height: 56px;
    .title {
      color: var(--text-color);
      font-weight: 500;
      font-size: 16px;
    }
   
    .titleBorder {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  .content_box {
    height: 240px;
    width: 100%;
    display: flex;
    align-items: center;
    .chart_box{
      flex: 1;
      height: 100%;
      width: 0px;
      .spin_box {
        width: 100%;
        height: 100%;
        margin: auto;
      }
      .empty_box {
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .desc_box {
      flex: 1;
      height: 180px;
      overflow-y: auto;
      background: rgba(74,144,226,0.1);
      border-radius: 2px;
      margin: 30px 20px;
      padding: 16px;
      .empty_box {
        width: 100%;
        height: 100%;
        margin: auto;
      }
      .peak_label {
        color:#E29E18 ; 
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ffffff40;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      .line {
        color: rgba(255,255,255,0.85);
        margin-bottom: 16px;
        .name_box {
          margin-left: 4px;
          display: inline-block;
          font-weight: 500;
          color: #16DD8E;
          background: #16DD8E20;
          border-radius: 2px;
          padding: 0 8px;
        }
      }
    }
  }
}

