.wrapper {
  min-height: calc(100vh - 128px) !important;
  padding: 0;
  background: none;
}

.empty_box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.layout {
  min-height: calc(100vh - 128px);
  background-color: transparent;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 10px;
  background: #232324;
  border-radius: 2px;

  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .contentInner {
    flex: 1;
    padding: 20px;
  }
}

.topFilter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 16px;
}
