.page {
  padding: 8px 13px 8px;
  position: relative;
  background-color: #29292c;
}
.page::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.svg');
  opacity: 0.6;
}
.func_area {
  background: var(--component-background);
  padding: 10px;
  margin-bottom: 10px;
  height: calc(100% - 10px);
}
.title_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .settingSty {
    color: #4a90e2;
    cursor: pointer;
  }
}
.line_through {
  position: relative;
  margin: 2px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  .line_head {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 40px;
    border-bottom: 1px solid #14d0e4;
  }
}

.pd_horizal {
  padding: 0 12px;
}

.titleOutDivSty {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 7px;
}

.firstRowSty {
  min-height: 183px;
}

.secondRowSty {
  min-height: 256px;
  // height: calc((100vh - 220px) / 16 * 5);
}

.thirdRowSty {
  min-height: 292px;
  // height: calc((100vh - 220px) / 16 * 7);
}
