.container {
  display: flex;
  flex-direction: column;
  .headerWrapper {
    display: flex;
    align-items: center;
    height: 56px;
    .title {
      color: var(--text-color);
      font-weight: 500;
      font-size: 16px;
    }
   
    .titleBorder {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  .content_box {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chart_box{
      width: 57%;
      height: 100%;
      .empty_box {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .calc_box {
      width: 42%;
      height: 100%;
      .center_line_box {
        height: 22px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .center_line_box_dash {
          height: 1px;
          width: calc((100% - 160px) / 2);
          border: 1px dashed rgba(74,144,226,0.5);
        }
        .center_line_box_text {
          width: 160px;
          font-weight: 500;
          font-size: 14px;
          color: rgba(255,255,255,0.85);
          line-height: 22px;
          text-align: center;
          margin: 0px 16px;
          background-image: url('../imgs/tittle.png');
          background-repeat: no-repeat;
        }
      }
      .card_box {
        height: calc(100% - 22px);
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty_box {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.wrapper {
  width: 100%;
  height: 72px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  .left_box{
    width: 100px;
    height: 100%;
    background: #314B68;
    position: relative;
    .year {
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 12px;
      color: rgba(255,255,255,0.65);
    }
    .date {
      position: absolute;
      right: 12px;
      bottom: 12px;
      font-weight: 500;
      font-size: 18px;
      color: rgba(255,255,255,0.85);
    }
  }
  .right_box {
    width: calc(100% - 100px);
    height: 100%;
    background: #2B394A;
    display: flex;
    justify-content: center;
    align-items: center;
    .item_data {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        color: rgba(255,255,255,0.65);
      }
      .value {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}