.chart_box {
  width: 100%;
  height: 100%;
  .spin_box {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .empty_box {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
