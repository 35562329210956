.content {
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 15px;
  }

  .col {
    display: flex;
    align-items: center;
  }
}
