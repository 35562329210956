.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  .label {
    width: 100%;
    color: rgba(255,255,255,0.65);
    text-align: left;
  }

  .left_card {
    flex: 1;
    height: 100%;
    padding: 8px 16px;
    background: rgba(74,144,226,0.05);
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    .top_left_box {
      height: 50%;
      width: 50%;
      border-bottom: 1px dashed rgba(255,255,255,0.12);
      border-right: 1px dashed rgba(255,255,255,0.12);
      padding-right: 16px;
      padding-bottom: 3px;
      .value {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #00ADFF;
        text-align: right;
      }
    }
    .top_right_box {
      height: 50%;
      width: 50%;
      border-bottom: 1px dashed rgba(255,255,255,0.12);
      padding-left: 16px;
      padding-bottom: 3px;
      .value {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #16DD8E;
        text-align: right;
      }
    }
    .bottom_left_box {
      height: 50%;
      width: 50%;
      border-right: 1px dashed rgba(255,255,255,0.12);
      padding:3px 16px 3px 0px;
      .value {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #FAAD14;
        text-align: right;
      }
    }
    .bottom_right_box {
      height: 50%;
      width: 50%;
      padding-left: 16px;
      padding-top: 3px;
      .value_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .value {
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          background: #214172;
          border-radius: 2px;
          padding: 0 8px;
        }
      }
    }
  }
  .right_card {
    flex: 1;
    display: flex;
    height: 100%;
    padding: 8px 16px;
    background: rgba(74,144,226,0.05);
    border-radius: 2px;
    .left_card_box {
      flex: 1;
      border-right: 1px dashed rgba(255,255,255,0.12);
      .value {
        font-weight: 500;
        font-size: 16px;
        text-align: left;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(180deg, #51DBFF 0%, #51A1FF 100%);
        margin:2px 0px 8px
      }
      .progress_box {
        width: 90%;
        margin-top: 2px;
      }
    }
    .right_card_box {
      flex: 1;
      padding-left: 16px;
      .value {
        font-weight: 500;
        font-size: 16px;
        text-align: left;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(180deg, #51DBFF 0%, #51A1FF 100%);
        margin:2px 0px 8px
      }
      .progress_box {
        width: 90%;
        margin-top: 2px;
      }
    }
  }
}