#container {
  width: 100%;
  height: 100%;
}

.WrapperStyle {
  position: relative;
  padding: 0;
}

.border-wrapper {
  border-style: solid;
  border-width: 140px 180px;
  border-image-source: url('../../assets/images/border.svg');
  border-image-slice: 140 180;
  border-image-repeat: repeat;
}

.border-wrapper-inner {
  margin: -123px -163px -122px -163px;
  position: relative;
  overflow: hidden;
}

.pageContent {
  min-height: unset !important;
}

.LeftWapper {
  position: absolute;
  z-index: 1;
}

.TopWapper {
  position: absolute;
  z-index: 1;
  left: 320px;
  width: calc(100% - 600px);
}

.RightWapper {
  position: absolute;
  z-index: 1;
  right: 0;
}

:global {
  @keyframes rotates {
    to {
      transform: rotate(var(--deg));
    }
  }

  .maker-cluster {
    position: relative;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    backdrop-filter: blur(1px);

    cursor: pointer;
    &.size-mode-1 {
      width: 48px;
      height: 48px;
      background-image: url('./imgs/circle-out-s.png');
      background-size: 48px 48px;
      &::before {
        width: 45px;
        height: 45px;
        background-image: url('./imgs/cirle-in-s.png');
        background-size: 45px 45px;
      }
      &::after {
        width: 41px;
        height: 41px;
        background-image: url('./imgs/arrow-s.png');
        background-size: 41px 41px;
      }
    }
    &.size-mode-2 {
      width: 60px;
      height: 60px;
      background-image: url('./imgs/circle-out-m.png');
      background-size: 60px 60px;
      &::before {
        width: 56px;
        height: 56px;
        background-image: url('./imgs/cirle-in-m.png');
        background-size: 56px 56px;
      }
      &::after {
        width: 51px;
        height: 51px;
        background-image: url('./imgs/arrow-m.png');
        background-size: 51px 51px;
      }
    }
    &.size-mode-3,
    &.size-mode-4,
    &.size-mode-5 {
      width: 80px;
      height: 80px;
      background-image: url('./imgs/circle-out-L.png');
      background-size: 80px 80px;
      &::before {
        width: 72px;
        height: 72px;
        background-image: url('./imgs/cirle-in-L.png');
        background-size: 72px 72px;
      }
      &::after {
        width: 68px;
        height: 68px;
        background-image: url('./imgs/arrow-L.png');
        background-size: 68px 68px;
      }
    }
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-size: cover;
      &:local {
        animation: rotates 3s linear infinite;
      }
    }

    &::before {
      --deg: 360deg; /* 内环顺时针 */
    }
    &::after {
      --deg: -360deg; /* 箭头逆时针 */
    }

    & > span {
      position: absolute;
      white-space: nowrap;
      display: block;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
  }

  .amap-logo {
    display: none !important;
    visibility: hidden !important;
  }

  .amap-copyright {
    display: none !important;
    visibility: hidden !important;
  }
}
