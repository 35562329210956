.searchArea {
  padding: 0 10px;
  margin: 12px 0;
}

.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;

  .treeBox {
    flex: 1;
    overflow: auto;
  }

  :global {
    .ant-spin-nested-loading {
      height: 100px;
    }
  }
}
