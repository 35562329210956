.wrapper {
  height: 100vh;
  background-image: url('../../assets/images/energyGateway/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.eneygyGateWaybox {
  position: relative;
  z-index: 2;

  video {
    width: 100%;
  }

  .fullscreen {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 25px;
    top: 25px;
    width: 102px;
    height: 36px;
    background-image: url('../../assets/images/energyGateway/fullScreenButton-bg@2x.png');
    background-size: 102px auto;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-image: url('../../assets/images/energyGateway/button-bghover@2x.png');
    }

    .icon {
      font-size: 16px;
      margin-right: 4px;
    }

    .text {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
    }
  }
}
