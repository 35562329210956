.edgeDeviceConfigPreviewTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.subTitle {
  font-width: 500;
  font-size: 16px;
  margin-bottom: 15px;
}
