.page_box {
  :global {
    .page__content {
      min-height: calc(100vh - 128px);
    }
  }
}

.actionDropdown {
  :global {
    .ant-btn-disabled {
      border: none;
    }
  }
}

.actionWrap {
  :global {
    .ant-btn {
      padding: 4px 0;
    }
  }
}

.action_line {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .click_text {
    color: var(--primary-color);
    cursor: pointer;
  }
}

.applyLog {
  max-height: 500px;
  overflow-y: auto;
}

.danger {
  color: #f00;
}
