.outer_wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: radial-gradient( circle at 50% 50%, #000000 0%, #051A3A 100%);
  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    .inner_top {
      height: 56px;
      width: 100%;
    }
    .inner_center {
      height: 0px;
      flex: 2;
      display: flex;
      align-items: center;
      .center_left_box {
        height: 100%;
        width: 480px;
      }
      .center_center_box {
        height: 100%;
        flex: 1;
      }
      .center_right_box {
        height: 100%;
        width: 480px;
      }
    }
    .inner_bottom {
      height: 0px;
      flex: 1;
      display: flex;
      align-items: center;
    }
  } 
}