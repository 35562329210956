.content {
  padding: 0px 20px 16px;
  .connect_table_box {

    padding-top: 20px;
    white-space:nowrap;
    overflow-x: auto;
    overflow-y: hidden
  }
  .common_table, .connect_table {
    :global {
      .ant-table-tbody > tr {
        .self_color {
          background: #29292C;
         
        }
      }
      .ant-table-thead >tr>th  {
        border-bottom: 1px solid #303030!important;
        padding: 12px 16px;
        &::before {
          position: absolute;
          top: 50%;
          inset-inline-end: 0;
          width: 1px;
          height: 1.6em;
          background-color: #303030;
          transform: translateY(-50%);
          transition: background-color 0.2s;
          content: "";
        }
      }
      
    }
  }

  :global {
    
    .ant-table-cell {
      &.noRightBorder{
        &::before {
          width: 0px !important;
        }
      }
      &.hasBottomBorder {
        border-bottom: 1px solid #303030 !important;
      }
      &.hasBottomAndRightBorder {
        border-bottom: 1px solid #303030 !important;
        &::before {
          position: absolute;
          top: 50%;
          inset-inline-end: 0;
          width: 1px;
          height: 1.6em;
          background-color: #303030;
          transform: translateY(-50%);
          transition: background-color 0.2s;
          content: "";
        }
      }
    }
  }
}
.connect_table {
  border: 1px solid rgba(255,255,255,0.2);
  width: 570px;
  display: inline-block;
  margin-right: 16px;
}
.title_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255,255,255,0.85);
  .img_box {
    width: 14px;
    img {
      width: 100%;
      display: block;
    }
  }
  .main_title {
    font-size: 14px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title_label {
    font-size: 12px;
    color: rgba(255,255,255,0.65);
  }
  .right_label {
    cursor: pointer;
    color: var(--primary-color)
  }
}
