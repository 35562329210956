.wrapper {
  padding: 20px;

  .btn_box {
    padding: 0px 32px 10px;
  }
  .table_wrapper {
    padding: 0px 32px;
  }
}

.sharpPrice {
  font-size: 12px;
  color: #ff6d2c;
}
.peakPrice {
  font-size: 12px;
  color: #ce90d1;
}
.flatPrice {
  font-size: 12px;
  color: #0db6d9;
}
.valleyPrice {
  font-size: 12px;
  color: #63bc7f;
}
