.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    flex: 1;
    height: 0px;
    display: flex;
  }
  .bottom {
    flex: 1;
    height: 0px;
    display: flex;
  }
}