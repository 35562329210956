.compute_box {
  background: rgba(255,255,255,0.05);
  border-radius: 2px;
  border: 1px solid rgba(255,255,255,0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 16px;
  box-sizing: border-box;
  color: rgba(255,255,255,0.65);
  .throug_line {
    height: 1px;
    background: rgba(255,255,255,0.1);
  }
  .compute_value {
    display: flex;
    padding: 10px 0;
    .text {
      padding-right: 5px;
    }
    .value {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .compute_formula {
    padding: 10px 0;
  }
}