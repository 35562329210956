.wrapper {
  padding: 20px;
}

.elec_forecast {
  margin-top: 20px;
}

.income_forecast {
  margin-top: 20px;
}