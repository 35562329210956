.pvTreeWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .searchInput {
    margin: 16px 10px;
    width: calc(100% - 20px);
  }

  .divider {
    margin: 0 10px;
    height: 1px;
    margin-bottom: 9px;
    background: rgba(255, 255, 255, 0.15);
  }

  .tree {
    //flex: 1;
    overflow: auto;
    height: calc(100vh - 212px);
  }
}