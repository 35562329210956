:root {
  --gradient-background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);
}
html.theme-dark {
  --mx-bg-image: url('./assets/images/bg.svg');
}

.App {
  height: 100%;
}

.page {
  padding: 8px 13px 8px;
  position: relative;
  background-color: var(--mx-page-bg-color) !important;
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--mx-bg-image) !important;
    opacity: 0.6;
  }
  &__header {
    margin-bottom: 7px;
    padding-left: 10px;
    position: relative;
    height: 20px;
    box-sizing: border-box;
  }
  &__content {
    background: var(--mx-module-bg-color);
    padding: 20px;
    min-height: calc(100vh - 128px);
    &__header {
      margin-bottom: 15px;
    }
    &__section-title {
      font-size: 16px;
      color: var(--text-color);
      padding-left: 11px;
      line-height: 24px;
      position: relative;
      margin-top: 35px;
      &::before {
        content: '';
        position: absolute;
        background: var(--mx-primary-color);
        width: 4px;
        height: 16px;
        left: 0;
        top: 4px;
      }
    }
    &__footer {
      background-color: var(--mx-module-bg-color);
      margin-top: 20px;
      position: sticky;
      bottom: 0;
      z-index: 1000;
    }
  }
}

.sticky-footer-left {
  position: sticky;
  left: 0;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}

.action-buttons {
  .ant-btn-text {
    padding: 0;
    line-height: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .ant-btn-link {
    padding: 0;
    line-height: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 2px;
  }
}

$invalid-color: #ff7875;

.device-invalid {
  background-color: $invalid-color;
  td {
    background-color: $invalid-color;
  }
  td.ant-table-cell-row-hover {
    background-color: $invalid-color !important;
  }
}

@media only screen and(max-width:1366px) {
  body {
    min-width: 1366px;
  }
}

.modal-form-content {
  padding: 32px 72px 8px 72px !important;
}

.wrapper-padding {
  padding: 20px !important;
  min-height: calc(100vh - 128px);
}

::-webkit-scrollbar {
  border-radius: 0;
  background: var(--mx-scrollbar-bg);
  width: 8px;
  height: 8px;
}

//滚动条上滑块
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-bg);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-hover-bg);
}
