.content {
  padding: 0px 20px 16px;
  .pcs_table {
    :global {
      .ant-table-tbody > tr {
        .self_color {
          background: #29292C;
        }
      }

      .ant-table-thead >tr>th  {
        border-bottom: 1px solid #303030!important;
        &::before {
          position: absolute;
          top: 50%;
          inset-inline-end: 0;
          width: 1px;
          height: 1.6em;
          background-color: #303030;
          transform: translateY(-50%);
          transition: background-color 0.2s;
          content: "";
        }
      }
      
    }
  }

  :global {
    .self_row_color  {
      td {
        background-color: #1D1D1D!important;
      }
      td:not(:first-child) {
        color: transparent
      }
      
      
    }
    .ant-table-cell {
      &.noRightBorder{
        &::before {
          width: 0px !important;
        }
      }
      &.hasBottomBorder {
        border-bottom: 1px solid #303030 !important;
      }
      &.hasBottomAndRightBorder {
        border-bottom: 1px solid #303030 !important;
        &::before {
          position: absolute;
          top: 50%;
          inset-inline-end: 0;
          width: 1px;
          height: 1.6em;
          background-color: #303030;
          transform: translateY(-50%);
          transition: background-color 0.2s;
          content: "";
        }
      }
    }
  }
}

.select_all {
  padding: 16px 0;
}
.title_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255,255,255,0.85);
  .img_box {
    width: 16px;
    img {
      width: 100%;
      display: block;
    }
  }
  .main_title {
    font-size: 14px;
  }
  .title_label {
    font-size: 12px;
    color: rgba(255,255,255,0.65);
  }
  .right_label {
    cursor: pointer;
    color: var(--primary-color)
  }
}