.treeArea {
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;

  .searchArea {
    padding: 0 10px;
    margin: 12px 0;

    :global {
      .ant-input {
        background-color: transparent;
      }
    }
  }

  .treeBox {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
}

.iconSty {
  width: 16px;
  height: 16px;
}

.filter_text {
  color: red;
}

.btnSty {
  padding: 0 8px;
  height: 32px;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    background: #404040;
  }
  .imgSty {
    width: 16px;
    height: 16px;
  }
}

.titleButtonUnChecked {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px !important;
}

.titleButtonChecked {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #404040;
  border-radius: 2px !important;
  // color: var(--mx-primary-color);
  border-left: 2px solid #00adff;
}
