.cardListStyle {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

%animation-share {
  content: '';
  left: 50%;
  top: 50%;
  position: absolute;
  width: 1em;
  height: 1em;
  margin: -50% -50%;
  border-radius: 50%;
  border: 1px solid #ec2020;
}

@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(0.2);
    opacity: 0.2;
  }
  20% {
    transform: scale(0.4);
    opacity: 0.4;
  }
  30% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  40% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  60% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  80% {
    transform: scale(1.6);
    opacity: 0.4;
  }
  90% {
    transform: scale(1.6);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;

  width: calc((100% - 24px) / 3);
  height: 295px;
  background: linear-gradient(135deg, #303b51 0%, #1a2737 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-top: 1px solid transparent;

  &:hover {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    border-top: 1px solid #4a90e2;
  }

  &.cardOffline {
    background: #333;
    border: 1px solid rgba(153, 153, 153, 0.11);

    .topTipLine {
      background: #b3b3b3;
    }
  }

  &.isAlarm {
    .topTitle {
      .name {
        color: red;
      }

      .icon {
        position: relative;
        color: red;

        &:before {
          @extend %animation-share;
          animation: wave 2s ease-in-out 0.4s infinite normal none;
        }

        &:after {
          @extend %animation-share;
          animation: wave 2s ease-in-out 1.6s infinite normal none;
        }
      }
    }
  }
}

.topTipLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 36px;
  background: #12c6ff;
  border-radius: 2px 0px 2px 0px;
}

.topTitle {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 8px;
  line-height: 20px;
  font-size: 14px;

  .icon {
    margin-right: 4px;
  }

  .name {
    margin-right: 8px;
  }
}

.statistics {
  margin-top: 14px;

  .statisticsItem {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 54px;
      background: rgba(255, 255, 255, 0.12);
    }

    &:last-of-type:after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 54px;
      background: rgba(255, 255, 255, 0.12);
    }

    .value {
      font-weight: bold;
      color: #00adff;
      line-height: 17px;
    }

    .label {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.detailWrap {
  flex: 1;
  overflow: auto;
  margin-top: 14px;
  padding-left: 12px;
  font-size: 12px;

  .label {
    color: var(--text-color-secondary);
    font-size: 12px;
  }

  .item {
    width: 100%;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    font-size: 12px;

    > * {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.footer {
  height: 32px;
  padding-right: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.12);
  }
}

.wFull {
  width: 100%;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
