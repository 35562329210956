.picker {
  display: inline-flex;
  align-items: center;

  .datePickerArea {
    margin-left: 48px;
  }
}

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;

  &:after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}
