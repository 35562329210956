.contentHeader {
  display: flex;
  justify-content: space-between;
}

.cabinetList {
  margin-top: 10px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  .cabinetBox {
    max-width: calc((100% - 16px) / 2);
    padding: 16px;
    background: #2d2d2d;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .leftName {
        margin-right: 8px;
        font-size: var(--mx-font-size-H2);
        font-weight: 500;
        color: var(--mx-text-base-color);
        line-height: var(--mx-line-height-H2);
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
      .middleTag {
        flex-shrink: 0;
      }
      .rightOpt {
        flex-shrink: 0;
        margin-left: auto;
        .editLink {
          padding: 8px;
        }
        .delLink {
          padding: 8px 0 8px 8px;
        }
      }
    }
    .middle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      .sortBtn {
        padding: 0;
      }
    }
    .desc {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      padding-right: 32px;
      font-size: var(--mx-font-size-normal);
      font-weight: 400;
      color: var(--mx-text-secondary-color);
      line-height: var(--mx-line-height-normal);
    }
    .divider {
      margin-top: 8px;
      margin-bottom: 15px;
      border-block-start: 1px solid rgba(255, 255, 255, 0.2);
    }
    .table {
      height: 180px;
      :global {
        .ant-spin-nested-loading {
          height: 100%;
          .ant-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
          .ant-table-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            .ant-table-body {
              position: relative;
              flex: 1;
              table {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
              }
            }
          }
          .ant-table {
            flex: 1;
          }
        }
      }
    }
    .addBtn {
      width: 100%;
    }
    .btnWrap {
      width: 100%;
      margin-top: 4px;
    }
  }
}
