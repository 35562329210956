.title {
  padding: 10px 0 20px;
  font-size: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
}

.inverter {
  padding: 20px;
  background-color: var(--mx-module-bg-color);
  margin-top: 15px;
  min-height: calc(100vh - 280px);
}
